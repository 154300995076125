<template>
  <div class="wrap w-full h-full">
    <div class="w-full h-full flex flex-col">
      <!-- Header -->
      <main-header :app-menu="appMenu" :sign-user="signUser" @click:signout="onSignout" />

      <!-- 메뉴 타이틀 -->
      <div>
        <div class="px-2 py-1 text-lg text-white font-D2CodingBold border-b bg-primary">
          <span @click="onClickAppMenu">{{ currentMenuName.appMenu }}</span> >
          <span v-if="currentMenuName?.subMenu">{{ currentMenuName.subMenu }}</span>
        </div>
      </div>

      <!-- Body -->
      <div class="container h-full">
        <router-view />
      </div>

      <!-- Footer -->
      <main-footer class="" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { MODULE_NAME as APP_MODULE_NAME } from '@/store/modules/app/store.modules.app.js';
import { GETTER_NAMES as APP_GETTER_NAMES } from '@/store/modules/app/getter.js';
import { MODULE_NAME as COMPANY_MODULE_NAME } from '@/store/modules/company/store.modules.company.js';
import { ACTION_NAMES as COMPANY_ACTION_NAMES } from '@/store/modules/company/action.js';
import { ACTION_NAMES as AUTH_ACTION_NAMES } from '@/store/modules/auth/action.js';
import { MODULE_NAME as AUTH_MODULE_NAME } from '@/store/modules/auth/store.modules.auth.js';
import MainHeader from './MainHeader.vue';
import MainFooter from './MainFooter.vue';

const store = useStore();
const router = useRouter();
const route = useRoute();
const signUser = ref(null);
const appMenu = ref([]);

const currentMenuName = computed(() => {
  return route?.meta?.parentName
    ? { appMenu: route?.meta?.parentName, subMenu: route?.meta?.menuName }
    : { appMenu: route?.meta?.menuName };
});

const getCompanyName = async () => {
  try {
    const response = await store.dispatch(
      `${COMPANY_MODULE_NAME}/${COMPANY_ACTION_NAMES.FETCH_DETAIL_COMPANY}`,
      signUser.value.chDivId,
    );

    if (response.success) {
      signUser.value.chDivCd = response.data.chDivCd;
    }
  } catch (error) {
    console.error('error', error);
  }
};

const onSignout = () => {
  try {
    store.dispatch(`${AUTH_MODULE_NAME}/${AUTH_ACTION_NAMES.SIGN_OUT}`);
  } catch (error) {
    console.error('error', error);
  } finally {
    router.push({ name: 'sign' });
  }
};

const onClickAppMenu = () => {
  if (route.name === 'myArticleView') {
    router.push({ name: 'myArticle' });
  } else if (route.name === 'articleView') {
    router.push({ name: 'myRegion' });
  } else if (route.name === 'cuesheetDetail') {
    router.push({ name: 'myCuesheet' });
  }
};

onMounted(() => {
  appMenu.value = store.getters[`${APP_MODULE_NAME}/${APP_GETTER_NAMES.GET_APP_MENU}`];
  signUser.value = store.getters[`${APP_MODULE_NAME}/${APP_GETTER_NAMES.GET_MY_INFO}`];
  getCompanyName();
});
</script>
