export const MUTATION_NAMES = Object.freeze({
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
  CLEAR_TOKEN: 'CLEAR_TOKEN',
});

export const MUTATION = {
  [MUTATION_NAMES.SET_ACCESS_TOKEN]: (state, accessToken) => {
    state.accessToken = accessToken;
  },
  [MUTATION_NAMES.CLEAR_TOKEN]: state => {
    state.accessToken = '';
  },
};
