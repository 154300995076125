const PREFIX = '/article';
const headers = {
  'Content-Type': 'multipart/form-data',
};

export default $axios => {
  return {
    /**
     * 전체 기사 목록 조회
     * @param params
     * @returns
     */
    findAll: async params => {
      return $axios.get(`${PREFIX}`, { params }).then(response => response.data);
    },

    /**
     * 전체 기사 목록 조회 (엘라스틱)
     * @param params
     * @returns
     */
    findAllElastic: async params => {
      return $axios.get(`${PREFIX}/search`, { params }).then(response => response.data);
    },

    /**
     * 기사 저장
     * @param params
     * @returns
     */
    create: async params => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 기사 상세 조회
     * @param articleId
     * @returns
     */
    findOne: async articleId => {
      return $axios.get(`${PREFIX}/${articleId}`).then(response => response.data);
    },

    /**
     * 기사 수정
     * @param articleId
     * @returns
     */
    update: async (articleId, params) => {
      return $axios.put(`${PREFIX}/${articleId}`, params).then(response => response.data);
    },

    /**
     * 기사 삭제
     * @param articleId
     * @returns
     */
    delete: async articleId => {
      return $axios.delete(`${PREFIX}/${articleId}`).then(response => response.data);
    },

    /**
     * 기사 복사
     * @param articleId
     * @returns
     */
    copy: async (articleId, params) => {
      return $axios.post(`${PREFIX}/${articleId}/copy`, params).then(response => response.data);
    },

    /**
     * 기사 출고 상태 변경
     * @param params
     * @returns
     */
    updateApprove: async (articleId, params) => {
      return $axios.put(`${PREFIX}/${articleId}/approve`, params).then(response => response.data);
    },

    /**
     * 기사 잠금
     * @param articleId 기사 아이디
     * @returns
     */
    lock: async articleId => {
      return $axios.put(`${PREFIX}/${articleId}/lock`).then(response => response.data);
    },

    /**
     * 기사 잠금 해제
     * @param {number} articleId 기사 아이디
     * @param {object} option - request body
     * @param {boolean} option.isForce - 강제 잠금해제 여부(필수 아님)
     * @returns
     */
    unLock: async (articleId, params = { isForce: false }) => {
      return $axios.put(`${PREFIX}/${articleId}/unlock`, params).then(response => response.data);
    },

    /**
     * 기사 목록 엑셀 다운로드
     * @param {object} option - request body
     * @param {boolean} option.startDate - 시작 시간
     * @param {boolean} option.endDate - 종료 시간
     * @param {boolean} option.apprvTypCds - 출고여부
     * @param {boolean} option.chDivIds - 기역사 ID
     * @param {boolean} option.artclTypCd - 기사 형식
     * @param {boolean} option.rptrId - 리포터 ID
     * @param {boolean} option.searchValue - 검색어
     * @param {boolean} option.page - 페이지
     * @param {boolean} option.size - 총 불러올 개수
     * @returns
     */
    excelDownload: async params => {
      return $axios
        .get(`${PREFIX}/download`, {
          params,
          responseType: 'blob',
          headers,
        })
        .then(response => response.data);
    },
  };
};
