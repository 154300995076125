<template>
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <title />
    <g id="Layer_2" data-name="Layer 2">
      <path
        d="M29.47,14.12a1,1,0,0,0-1,0l-4.45,3V14a1,1,0,0,0-1-1H22a5,5,0,0,0,1-3,5,5,0,0,0-8.56-3.51A6,6,0,1,0,4.54,13H3a1,1,0,0,0-1,1V28a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V24.87l4.45,3A1,1,0,0,0,30,27V15A1,1,0,0,0,29.47,14.12ZM18,7a3,3,0,1,1-3,3A3,3,0,0,1,18,7Zm-4,6h-.57q.16-.18.31-.37C13.85,12.76,13.93,12.88,14,13ZM5,9a4,4,0,1,1,4,4A4,4,0,0,1,5,9ZM22,27H4V15H22V27Zm6-1.87-4-2.67V19.54l4-2.67Z"
      />
    </g>
  </svg>
</template>

<script setup></script>
