import { useMarsLApi } from '@/apis/mars-l-api/mars-l-api.js';
import { MUTATION_NAMES } from './mutation.js';

export const ACTION_NAMES = Object.freeze({
  FETCH_MY_ARTICLES: 'FETCH_MY_ARTICLES',
  FETCH_DETAIL_MY_ARTICLE: 'FETCH_DETAIL_MY_ARTICLE',
  CREATE_MY_ARTICLE: 'CREATE_MY_ARTICLE',
  EDIT_MY_ARTICLE: 'EDIT_MY_ARTICLE',
  REMOVE_MY_ARTICLE: 'REMOVE_MY_ARTICLE',
  FETCH_MY_ARTICLE_LINK: 'FETCH_MY_ARTICLE_LINK',
  FETCH_MY_ARTICLE_FILE: 'FETCH_MY_ARTICLE_FILE',
  FETCH_TRANSFER: 'FETCH_TRANSFER',
});

export const ACTIONS = {
  [ACTION_NAMES.FETCH_MY_ARTICLES]: async ({ commit }, payload) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.myArticle.basic.findAll(payload);
      commit(MUTATION_NAMES.FETCH_MY_ARTICLES, response.data);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.FETCH_DETAIL_MY_ARTICLE]: async ({ commit }, payload) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.myArticle.basic.findOne(payload);
      commit(MUTATION_NAMES.FETCH_DETAIL_MY_ARTICLE, response.data);
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.CREATE_MY_ARTICLE]: async (context, payload) => {
    const marsLApi = useMarsLApi();

    try {
      return marsLApi.myArticle.basic.create(payload);
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.EDIT_MY_ARTICLE]: async (context, payload) => {
    const marsLApi = useMarsLApi();

    const { id, params } = payload;

    try {
      return marsLApi.myArticle.basic.update(id, params);
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.REMOVE_MY_ARTICLE]: async (context, payload) => {
    const marsLApi = useMarsLApi();
    try {
      return await marsLApi.myArticle.basic.delete(payload);
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.FETCH_MY_ARTICLE_LINK]: async ({ commit }, payload) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.myArticle.link.findAll(payload);
      commit(MUTATION_NAMES.FETCH_MY_ARTICLE_LINK, response.data);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.FETCH_MY_ARTICLE_FILE]: async ({ commit }, payload) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.myArticle.file.findAll(payload);
      commit(MUTATION_NAMES.FETCH_MY_ARTICLE_FILE, response.data);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.FETCH_TRANSFER]: async (context, payload) => {
    const marsLApi = useMarsLApi();

    try {
      return marsLApi.myArticle.transfer.move(payload);
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },
};
