const PREFIX = '/cuesheet-item';

export default $axios => {
  return {
    /**
     * 큐시트 아이템 목록 조회
     * @param params
     * @returns
     */
    findAll: async params => {
      return $axios.get(`${PREFIX}`, { params }).then(response => response.data);
    },

    /**
     * 큐시트 아이템 상세 조회
     * @param cuesheetItemId cuesheet item ID
     * @returns
     */
    findOne: async cuesheetItemId => {
      return $axios.get(`${PREFIX}/${cuesheetItemId}`).then(response => response.data);
    },

    /**
     * 큐시트 아이템 생성[Drag and Drop] By Article
     * @param articleId 기사 ID
     * @param params
     * @returns
     */
    createArticle: async params => {
      return $axios.post(`${PREFIX}/article-item`, params).then(response => response.data);
    },

    /**
     * 큐시트 아이템 등록 (아이템 항목)
     * @param params
     * @returns
     */
    createItem: async params => {
      return $axios.post(`${PREFIX}/empty-item`, params).then(response => response.data);
    },

    /**
     * 큐시트 아이템 템플릿(운영참조) 등록
     * @param templateId  큐시트 템플릿 ID
     * @returns
     */
    createTemplate: async params => {
      return $axios.post(`${PREFIX}/template-item-list`, params).then(response => response.data);
    },

    /**
     * 다른 큐시트의 큐시트 아이템 드래그앤드랍
     * @param cuesheetItemId 큐시트 아이템 아이디
     * @param params order
     * @returns
     */
    createCuesheetItem: async params => {
      return $axios.post(`${PREFIX}/cuesheet-item`, params).then(response => response.data);
    },

    /**
     * 큐시트 아이템 수정
     * @param cuesheetItemId 큐시트 아이템 아이디
     * @param params 수정할 데이터
     * @returns
     */
    update: async (cuesheetItemId, params) => {
      return $axios.put(`${PREFIX}/${cuesheetItemId}`, params).then(response => response.data);
    },

    /**
     * 큐시트 아이템 삭제
     * @param cuesheetItemId - 큐시트 아이템 ID
     */
    delete: async (cuesheetItemId, params) => {
      return $axios
        .delete(`${PREFIX}/${cuesheetItemId}`, { params })
        .then(response => response.data);
    },

    /**
     * 큐시트 아이템 순서 편집
     * @param cuesheetItemId 큐시트 아이템 아이디
     * @param params
     * @returns
     */
    updateOrder: async (cuesheetItemId, params) => {
      return $axios
        .put(`${PREFIX}/${cuesheetItemId}/order-number`, params)
        .then(response => response.data);
    },

    /**
     * 앵커멘트 수정
     * @param cuesheetItemId 큐시트 아이템 아이디
     * @param params 앵커멘트 내용
     * @returns
     */
    updateAnchor: async (cuesheetItemId, params) => {
      return $axios
        .put(`${PREFIX}/${cuesheetItemId}/anchor-content`, params)
        .then(response => response.data);
    },

    /**
     * 앵커멘트 확정
     * @param cuesheetItemId 큐시트 아이템 아이디
     * @param params 확정 여부
     * @returns
     */
    assignAnchor: async (cuesheetItemId, params) => {
      return $axios
        .put(`${PREFIX}/${cuesheetItemId}/anchor-content-assign`, params)
        .then(response => response.data);
    },

    /**
     * 웹프롬프터 아이템 조회 && 인쇄 조회
     * @param params 큐시트 ID
     * @returns
     */
    findAllPrompter: async params => {
      return $axios.get(`${PREFIX}/web-prompter`, { params }).then(response => response.data);
    },

    /**
     * 큐시트 아이템 심볼 삭제 API
     * @param cuesheetItemId
     * @param symbolType
     * @returns
     */
    deleteSymbol: async (cuesheetItemId, params) => {
      return $axios
        .delete(`${PREFIX}/${cuesheetItemId}/symbol`, {
          params,
        })
        .then(response => response.data);
    },
  };
};
