const PREFIX = '/my-article';

export default $axios => {
  return {
    /**
     * 내기사 송고
     * @param {number} articleId 기사 ID
     */
    move: async articleId => {
      return $axios.put(`${PREFIX}/${articleId}/move`).then(response => response.data);
    },
  };
};
