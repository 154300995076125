class ApplicationDivisionCode {
  web = {
    id: 'web',
    name: 'web',
    label: '웹',
  };

  mobile = {
    id: 'mobile',
    name: 'mobile',
    label: '모바일',
  };

  values = [this.web, this.mobile];
}

export default new ApplicationDivisionCode();
