import { STATE } from './state.js';
import { GETTER } from './getter.js';
import { ACTIONS } from './action.js';
import { MUTATION } from './mutation.js';

export const MODULE_NAME = 'file';

export default {
  namespaced: true,
  state: STATE,
  getters: GETTER,
  actions: ACTIONS,
  mutations: MUTATION,
};
