export const MUTATION_NAMES = Object.freeze({
  FETCH_ARTICLES: 'FETCH_ARTICLES',
  FETCH_DETAIL_ARTICLE: 'FETCH_DETAIL_ARTICLE',
  FETCH_ARTICLE_LINK: 'FETCH_ARTICLE_LINK',
  FETCH_ARTICLE_FILE: 'FETCH_ARTICLE_FILE',
  IS_TRIGGER_GET_DATA: 'IS_TRIGGER_GET_DATA',
});

export const MUTATION = {
  [MUTATION_NAMES.FETCH_ARTICLES]: (state, articles) => {
    state.articles = articles;
  },
  [MUTATION_NAMES.FETCH_DETAIL_ARTICLE]: (state, article) => {
    state.detailArticle = article;
  },
  [MUTATION_NAMES.FETCH_ARTICLE_LINK]: (state, link) => {
    state.articleLink = link;
  },
  [MUTATION_NAMES.FETCH_ARTICLE_FILE]: (state, file) => {
    state.articleFile = file;
  },
  [MUTATION_NAMES.IS_TRIGGER_GET_DATA]: (state, isTrigger) => {
    state.isTriggerGetData = isTrigger;
  },
};
