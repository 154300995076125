const speedCode = {
  slow: {
    id: '001',
    name: '느림',
    label: '느림',
  },

  normal: {
    id: '002',
    name: '보통',
    label: '보통',
  },

  fast: {
    id: '003',
    name: '빠름',
    label: '빠름',
  },
};

export default speedCode;
