import router from '@/router/router.js';

let routerQueryParams = null;

/**
 * 내기사 목록 라우터 쿼리
 * {
 *    startDate: null,
 *    endDate: null,
 *    searchValue: null
 * }
 *
 * 취재데스크 목록 라우터 쿼리
 * {
 *    startDate: null,
 *    endDate: null,
 *    approve: null,
 *    program: null,
 *    channel: null,
 *    articleType: null,
 *    reporter: null,
 *    searchValue: null,
 * }
 *
 * 큐시트 목록 라우터 쿼리
 * {
 *    startDate: null,
 *    endDate: null,
 *    program: null,
 *    searchValue: null,
 * }
 */

/**
 * 이전 페이지로 돌아갔을 때, 라우터의 쿼리로 검색된 결과를 그대로 보여주기 위해 사용한다.
 * @param {String} routerName 라우터 이름
 * @param {Object} queryData url에 표시할 Object 형태의 쿼리 데이터
 */
const useRouterQuery = (routerName, params) => {
  routerQueryParams = params;
  router.push({
    name: routerName,
    query: params,
  });
};

const getRouteQuery = () => {
  return routerQueryParams;
};

/**
 * navigation bar를 통해 메뉴에 진입했을 경우,
 * 초기값 데이터로 검색 결과가 표시되도록 하기 위한 초기화 함수
 */
const clearRouteQuery = () => {
  routerQueryParams = null;
};

export { useRouterQuery, getRouteQuery, clearRouteQuery };
