import SignLayout from '@/components/layout/sign/SignLayout.vue';
import BaseLayout from '@/components/layout/base/BaseLayout.vue';
import ArticleLayout from '@/components/layout/article/ArticleLayout.vue';
import InvalidLayout from '@/components/layout/invalid/InvalidLayout.vue';
// import { router } from '@/constant';

const appRoutes = [
  {
    path: '/signin',
    name: 'sign',
    component: () => import('@/views/sign/SignView.vue'),
    meta: { layout: SignLayout },
  },
  {
    path: '/',
    name: 'home',
    redirect: '/my-article',
    // component: () => import('@/views/home/HomeView.vue'),
    meta: {
      layout: BaseLayout,
      requiresAuth: true,
      menuName: '홈',
    },
  },
  {
    path: '/my-article',
    name: 'myArticle',
    component: () => import('@/views/my-article/MyArticleView.vue'),
    meta: {
      layout: BaseLayout,
      requiresAuth: true,
      parent: 'myArticle',
      parentName: '내기사',
      icon: 'bookOpen',
      menuName: '내기사',
    },
  },
  {
    path: '/my-article-view/:id',
    name: 'myArticleView',
    component: () => import('@/views/my-article/ArticleView.vue'),
    meta: {
      layout: BaseLayout,
      requiresAuth: true,
      parent: 'myArticle',
      parentName: '내기사',
      icon: '',
      menuName: '내기사 상세보기',
    },
    props: true,
  },
  {
    path: '/article-create',
    name: 'createMyArticle',
    component: () => import('@/views/my-article/CreateMyArticleView.vue'),
    meta: {
      layout: ArticleLayout,
      requiresAuth: true,
      parent: 'myArticle',
      parentName: '내기사',
      icon: '',
      menuName: '기사 작성',
    },
  },
  {
    path: '/article-edit/:id',
    name: 'editMyArticle',
    component: () => import('@/views/my-article/EditMyArticleView.vue'),
    meta: {
      layout: ArticleLayout,
      requiresAuth: true,
      parent: 'myArticle',
      parentName: '내기사',
      icon: '',
      menuName: '기사 수정',
    },
    props: true,
  },
  {
    path: '/my-region',
    name: 'myRegion',
    component: () => import('@/views/article/MyRegionView.vue'),
    meta: {
      layout: BaseLayout,
      requiresAuth: true,
      parent: 'desk',
      parentName: '데스크',
      icon: 'clipboardDocumentList',
      menuName: '지역MBC데스크',
    },
  },
  {
    path: '/article-view/:id',
    name: 'articleView',
    component: () => import('@/views/article/ArticleView.vue'),
    meta: {
      layout: BaseLayout,
      requiresAuth: true,
      parent: 'desk',
      parentName: '데스크',
      icon: '',
      menuName: '기사 상세보기',
    },
    props: true,
  },
  // {
  //   path: '/create-article',
  //   name: 'createArticle',
  //   component: () => import('@/views/article/CreateArticleView.vue'),
  //   meta: {
  //     layout: BaseLayout,
  //     requiresAuth: false,
  //     parent: '',
  //     icon: '',
  //   },
  // },
  {
    path: '/my-cuesheet',
    name: 'myCuesheet',
    component: () => import('@/views/cuesheet/MyCuesheetView.vue'),
    meta: {
      layout: BaseLayout,
      requiresAuth: true,
      parent: 'cuesheet',
      parentName: '큐시트',
      icon: 'inbox',
      menuName: '지역MBC큐시트',
    },
  },
  {
    path: '/cuesheet-detail/:id',
    name: 'cuesheetDetail',
    component: () => import('@/views/cuesheet/CuesheetDetailView.vue'),
    meta: {
      layout: BaseLayout,
      requiresAuth: true,
      parent: 'cuesheet',
      parentName: '큐시트',
      icon: '',
      menuName: '지역MBC큐시트 상세',
    },
    props: true,
  },
  // 정보 공유 메뉴 start
  {
    path: '/news-source',
    name: 'newsSource',
    component: () => import('@/views/share-info/NewsSourceView.vue'),
    meta: {
      layout: BaseLayout,
      requiresAuth: true,
      parent: 'share-info',
      parentName: '정보공유',
      icon: 'inbox',
      menuName: '취재원',
    },
  },
  // 정보 공유 메뉴 end

  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/invalid/NotFound.vue'),
    meta: {
      layout: InvalidLayout,
    },
  },
];

export default appRoutes;
