import { createStore } from 'vuex';
import AuthModule, { MODULE_NAME as AUTH_MODULE_NAME } from './modules/auth/store.modules.auth.js';
import CompanyModule, {
  MODULE_NAME as COMPANY_MODULE_NAME,
} from './modules/company/store.modules.company.js';
import UserModule, { MODULE_NAME as USER_MODULE_NAME } from './modules/user/store.modules.user.js';
import AppModule, { MODULE_NAME as APP_MODULE_NAME } from './modules/app/store.modules.app.js';
import MyArticleModule, {
  MODULE_NAME as MY_ARTICLE_MODULE_NAME,
} from './modules/my-article/store.modules.my-article.js';
import ProgramModule, {
  MODULE_NAME as PROGRAM_MODULE_NAME,
} from './modules/program/store.modules.program.js';
import ArticleModule, {
  MODULE_NAME as ARTICLE_MODULE_NAME,
} from './modules/article/store.modules.article.js';
import CuesheetModule, {
  MODULE_NAME as CUESHEET_MODULE_NAME,
} from './modules/cuesheet/store.modules.cuesheet.js';
import CategoryModule, {
  MODULE_NAME as CATEGORY_MODULE_NAME,
} from './modules/category/store.modules.category.js';
import TagModule, { MODULE_NAME as TAG_MODULE_NAME } from './modules/tag/store.modules.tag.js';
import FileModule, { MODULE_NAME as FILE_MODULE_NAME } from './modules/file/store.modules.file.js';

export default createStore({
  modules: {
    [AUTH_MODULE_NAME]: AuthModule,
    [COMPANY_MODULE_NAME]: CompanyModule,
    [USER_MODULE_NAME]: UserModule,
    [APP_MODULE_NAME]: AppModule,
    [MY_ARTICLE_MODULE_NAME]: MyArticleModule,
    [PROGRAM_MODULE_NAME]: ProgramModule,
    [ARTICLE_MODULE_NAME]: ArticleModule,
    [CUESHEET_MODULE_NAME]: CuesheetModule,
    [CATEGORY_MODULE_NAME]: CategoryModule,
    [TAG_MODULE_NAME]: TagModule,
    [FILE_MODULE_NAME]: FileModule,
  },
});
