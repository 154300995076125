import myInfoApi from './my-info.api.js';
import myLogApi from './my-log.api.js';
import mySystemApi from './my-system.api.js';
import myAuthorityApi from './my-authority.api.js';

export default $axios => ({
  info: myInfoApi($axios),
  log: myLogApi($axios),
  system: mySystemApi($axios),
  authority: myAuthorityApi($axios),
});
