<template>
  <div class="wrap">
    <div
      class="px-2 leading-6 rounded-full text-white font-D2CodingBold"
      :class="[getBroadcastTypeData.backgroundColor, fontSize]"
    >
      {{ getBroadcastTypeData.name }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { broadcastTypeCode } from '@/constant/codes/codes.js';
import { colorType } from '@/type/type.js';

const props = defineProps({
  broadcastType: {
    type: String,
    required: true,
    default: '',
  },
  fontSize: {
    type: String,
    default: 'text-sm',
  },
});

const getBroadcastTypeData = computed(() => {
  switch (props.broadcastType) {
    case broadcastTypeCode.Tv.id:
      return {
        backgroundColor: colorType.broadcastType.background.tv,
        name: broadcastTypeCode.Tv.name,
      };
    case broadcastTypeCode.Radio.id:
      return {
        backgroundColor: colorType.broadcastType.background.radio,
        name: broadcastTypeCode.Radio.name,
      };
    default:
      return '';
  }
});
</script>
