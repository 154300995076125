import { createRouter, createWebHistory } from 'vue-router';
import { computed } from 'vue';
import store from '@/store/store.js';
import { MODULE_NAME as AUTH_MODULE_NAME } from '@/store/modules/auth/store.modules.auth.js';
import { GETTER_NAMES as AUTH_GETTER_NAMES } from '@/store/modules/auth/getter.js';
import { MODULE_NAME as APP_MODULE_NAME } from '@/store/modules/app/store.modules.app.js';
import { ACTION_NAMES as APP_ACTION_NAMES } from '@/store/modules/app/action.js';
import { GETTER_NAMES as APP_GETTER_NAMES } from '@/store/modules/app/getter.js';
import appRoutes from './app-routes.js';

const routes = appRoutes;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const isAuthenticated = computed(
  () => store.getters[`${AUTH_MODULE_NAME}/${AUTH_GETTER_NAMES.IS_AUTHENTICATED}`],
);

/**
 * 토큰 시간 갱신
 */
const fetchRefreshTokenRemainingTime = async () => {
  await store.dispatch(`${APP_MODULE_NAME}/${APP_ACTION_NAMES.FETCH_REFRESH_TOKEN_REMAINING_TIME}`);
};

/**
 * 내 정보 store에 저장
 */
const fetchMyInfo = async () => {
  Promise.all([
    store.dispatch(`${APP_MODULE_NAME}/${APP_ACTION_NAMES.FETCH_MY_INFO}`),
    store.dispatch(`${APP_MODULE_NAME}/${APP_ACTION_NAMES.FETCH_MY_SYSTEM_INFO}`),
  ]);
};

/**
 * 권한 갱신
 */
const fetchAuthority = async () => {
  let appAuthorities = store.getters[`${APP_MODULE_NAME}/${APP_GETTER_NAMES.GET_APP_AUTHORITIES}`];

  if (!appAuthorities.length) {
    await store.dispatch(`${APP_MODULE_NAME}/${APP_ACTION_NAMES.FETCH_APP_AUTHORITIES}`);
    appAuthorities = store.getters[`${APP_MODULE_NAME}/${APP_GETTER_NAMES.GET_APP_AUTHORITIES}`];
  }
};

router.beforeEach(async to => {
  // 인증 확인
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated.value) {
      router.push({
        name: 'sign',
        // query: { back_url: to.fullPath },
      });
      return;
    }
    await Promise.all([fetchAuthority(), fetchMyInfo(), fetchRefreshTokenRemainingTime()]);
  }
});

export default router;
