const PREFIX = '/my-article';

export default $axios => {
  return {
    /**
     * 내기사 링크 조회
     * @params {number} articleId 기사 ID
     */
    findAll: async articleId => {
      return $axios.get(`${PREFIX}/${articleId}/link`).then(response => response.data);
    },

    /**
     * 내기사 링크 등록
     * @param {number} articleId 기사 ID
     * @param {Object} option request body
     * @param {string} option.titl 링크 제목
     * @param {object} option.link 링크
     */
    create: async (articleId, params) => {
      return $axios.post(`${PREFIX}/${articleId}/link`, params).then(response => response.data);
    },

    /**
     * 내기사 링크 수정
     * @param {number} articleId 기사 ID
     * @param {number} linkId 링크 ID
     * @param {Object} option request body
     * @param {string} option.titl 링크 제목
     * @param {string} option.link 링크
     */
    update: async (articleId, linkId, params) => {
      return $axios
        .post(`${PREFIX}/${articleId}/link/${linkId}`, params)
        .then(response => response.data);
    },

    /**
     * 내기사 링크 삭제
     * @param {number} articleId 기사 ID
     * @param {number} linkId 링크 ID
     */
    delete: async (articleId, linkId) => {
      return $axios.delete(`${PREFIX}/${articleId}/link/${linkId}`).then(response => response.data);
    },

    /**
     * 내기사 링크 순서 변경
     * @param {number} articleId 기사 ID
     * @param {number} linkId 링크 ID
     * @param {Object} option request query
     * @param {number} option.ord 변경할 순번
     */
    order: async (articleId, linkId, params) => {
      return $axios
        .put(`${PREFIX}/${articleId}/link/${linkId}/order-number`, null, {
          params,
        })
        .then(response => response.data);
    },
  };
};
