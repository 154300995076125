export const capitalize = (input, token = ' ') => {
  let str = '';
  if (input) {
    const words = input.split(token);
    const CapitalizedWords = [];
    words.forEach(element => {
      CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
    });
    str = CapitalizedWords.join(token);
  }
  return str;
};

/**
 * 자릿수에 맞춰 원하는 문자열을 추가해서 새로운 문자열을 만든다.
 * @param {String} type 문자열을 앞에 추가할지 뒤에 추가할지 (start OR end)
 * @param {Number, String} arg 문자열
 * @param {Number} digit 자릿수
 * @param {String} addStr 새롭게 추가할 문자열
 * @returns {String}
 */
export const padSides = (type, arg, digit, addStr) => {
  if (type === 'start') {
    return arg.toString().padStart(digit, addStr);
  }
  return arg.toString().padEnd(digit, addStr);
};

/**
 * 문자 바이트 값을 반환
 * 알파벳, 숫자, 특수문자(* , @, - , _ , + , . , /) - 1byte
 * 알파벳, 숫자, 특수문자(* , @, - , _ , + , . , /) 제외 - 2byte
 * @param target - 문자열
 * @param pos - 문자 인덱스
 */
export const getByte = (target, pos = 0) => {
  if (target.length <= pos) {
    throw new Error(`pos cannot be equal or higher than target length`);
  }

  if (pos < 0) {
    throw new Error('pos cannot be less than 0');
  }

  return escape(target.charAt(pos)).length > 4 ? 2 : 1;
};

/**
 * 문자열 바이트 길이 계산
 * 알파벳, 숫자, 특수문자(* , @, - , _ , + , . , /) - 1byte
 * 알파벳, 숫자, 특수문자(* , @, - , _ , + , . , /) 제외 - 2byte
 * @param target - 문자열
 */
export const getBytes = target => {
  let charCount = 0;
  const targetLength = target.length;

  for (let k = 0; k < targetLength; k += 1) {
    charCount += getByte(target, k);
  }

  return charCount;
};

export const getByteArray = target => {
  const result = [];
  const targetLength = target.length;

  for (let i = 0; i < targetLength; i += 1) {
    result.push(getByte(target, i));
  }

  return result;
};

/**
 * 문자열을 바이트 기준으로 잘라서 반환 한다.
 * 문자 바이트 계산 방식
 * 알파벳, 숫자, 특수문자(* , @, - , _ , + , . , /) - 1byte
 * 알파벳, 숫자, 특수문자(* , @, - , _ , + , . , /) 제외 - 2byte
 * @param target - 문자열
 * @param sliceByte - 잘라낼 바이트 값
 * @returns {Array}
 */
export const sliceByByte = (target, sliceByte = 2) => {
  const result = [];
  let charCount = 0;
  let sliceStartIndex = 0;
  const targetLength = target.length;

  if (!targetLength) {
    return target;
  }

  for (let k = 0; k < targetLength; k += 1) {
    charCount += getByte(target, k);

    // 문자열바이트 합이 잘라낼 바이트 값보다 큰 경우 이전 값까지만 slice 처리
    if (charCount > sliceByte) {
      result.push(target.slice(sliceStartIndex, k));
      sliceStartIndex = k;
      charCount = getByte(target, k);

      // 마지막 문자일 경우 result에 해당 문자 push 처리
      if (targetLength === k + 1) {
        result.push(target.slice(sliceStartIndex, k + 1));
      }
    } else if (charCount === sliceByte) {
      result.push(target.slice(sliceStartIndex, k + 1));
      sliceStartIndex = k + 1;
      charCount = 0;
    } else if (targetLength === k + 1) {
      result.push(target.slice(sliceStartIndex, k + 1));
    }
  }

  return result;
};

/**
 * 문자열 앞뒤 \n을 빈문자열로 처리
 * @param {string} str - 문자열
 * @returns 앞뒤 공백 없는 문자열
 */
export const trimCarriageReturn = str => {
  return str.replace(/^\s+|\s+$/g, '');
};

/**
 * 문자열에서 원하는 문자열로 치환
 * @param {sting} str - 문자열
 * @param {sting} replaceStr - 변경할 문자열
 */
export const replaceText = (str, regExp, replaceStr) => {
  return str.replace(regExp, replaceStr);
};
