export const GETTER_NAMES = Object.freeze({
  GET_APP_AUTHORITIES: 'GET_APP_AUTHORITIES',
  GET_REFRESH_TOKEN_REMAINING_TIME: 'GET_REFRESH_TOKEN_REMAINING_TIME',
  GET_MY_INFO: 'GET_MY_INFO',
  GET_APP_MENU: 'GET_APP_MENU',
  IS_SHOW_MENU: 'IS_SHOW_MENU',
});

export const GETTER = {
  [GETTER_NAMES.GET_APP_AUTHORITIES]: state => state.appAuthorities,
  [GETTER_NAMES.GET_REFRESH_TOKEN_REMAINING_TIME]: state => state.refreshTokenRemainingTime,
  [GETTER_NAMES.GET_MY_INFO]: state => state.myInfo,
  [GETTER_NAMES.GET_APP_MENU]: state =>
    state.appMenu
      .map(menu => {
        // 그룹 메뉴
        if (menu.children?.length > 0) {
          const childrenMenu = menu.children.filter(childMenu =>
            state.appAuthorities.some(authority => childMenu.authorities.includes(authority)),
          );
          return { ...menu, children: childrenMenu };
        }

        // 단일 메뉴
        return state.appAuthorities.some(authority => menu.authorities.includes(authority))
          ? menu
          : [];
      })
      .filter(
        menu =>
          (menu.type === 'group' && menu.children.length > 0) ||
          (menu.type === 'single' && menu.authorities.length > 0),
      ),
  [GETTER_NAMES.IS_SHOW_MENU]: state => state.isShowMenu,
};
