<template>
  <div class="wrap">
    <div class="flex items-center leading-4">
      <g-icon-button
        aria-label="menu-button"
        type="secondary"
        icon="bars3"
        size="h-9 w-9"
        @click="fetchMenuState(!isShowMenu)"
      />

      <!-- 메뉴 목록 -->
      <div v-if="isShowMenu" class="w-full h-full fixed top-0 left-0 border text-lg z-50">
        <div class="w-full h-full app-menu-overlay opacity-20 bg-black relative"></div>
        <div class="h-full flex flex-col app-menu w-60 absolute inset-0 z-50 opacity-100 bg-white">
          <div class="border-b-2 pb-4 pt-2">
            <div class="flex w-32 mx-auto">
              <g-logo />
            </div>
          </div>
          <div class="w-full overflow-auto">
            <disclosure v-for="menu in appMenu" :key="menu.path" default-open as="div">
              <disclosure-button v-slot="{ open }" as="template">
                <div class="p-2 bg-primary text-white">
                  <div class="flex items-center justify-between">
                    <div>{{ menu.name }}</div>
                    <div v-if="menu.type === 'group'">
                      <component
                        :is="iconComponent('chevronUp')"
                        class="h-5 w-5"
                        aria-hidden="true"
                        :class="open ? 'rotate-180 transform' : ''"
                      />
                    </div>
                  </div>
                </div>
              </disclosure-button>
              <disclosure-panel v-for="child in menu.children" :key="child.name">
                <router-link :to="child.path" @click="onClickedSubMenu($event, child)">
                  <div class="flex items-center border-b px-3 py-2 space-x-2">
                    <component
                      :is="iconComponent(child?.icon)"
                      v-if="child?.icon"
                      class="h-5 w-5"
                      aria-hidden="true"
                    />
                    <div class="font-normal">{{ child.name }}</div>
                  </div>
                </router-link>
              </disclosure-panel>
            </disclosure>
          </div>
          <div class="mt-auto">
            <div class="flex flex-col space-y-2 items-center py-5 bg-primary-bright">
              <div class="flex items-center text-sm font-D2CodingBold">
                {{ signUser?.userNm }}({{ signUser?.userId }}) {{ signUser?.chDivCd }}
              </div>
              <div
                class="flex items-center rounded-full border px-6 py-2 leading-3 shadow-md bg-white"
              >
                <g-icon-button
                  icon="ArrowRightOnRectangle"
                  aria-label="signout-button"
                  type="secondary"
                  size="h-6 w-6"
                  :is-button-text="true"
                  button-text="로그아웃"
                  @click="onClickSignout"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
// eslint-disable-next-line import/extensions
import * as heroIcons from '@heroicons/vue/24/outline';
import { capitalize } from '@/utils/str.util.js';
import { MODULE_NAME as APP_MODULE_NAME } from '@/store/modules/app/store.modules.app.js';
import { GETTER_NAMES as APP_GETTER_NAMES } from '@/store/modules/app/getter.js';
import { ACTION_NAMES as APP_ACTION_NAMES } from '@/store/modules/app/action.js';
import { GIconButton } from '@/components/atoms/button/atoms.button.js';
import { GLogo } from '@/components/atoms/etc/atoms.etc.js';
import { clearRouteQuery } from '@/hooks/use-router-query.js';

defineProps({
  appMenu: {
    type: Array,
    default: () => {
      return [];
    },
  },
  signUser: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const emit = defineEmits(['click:signout']);

const store = useStore();

const fetchMenuState = payload => {
  store.dispatch(`${APP_MODULE_NAME}/${APP_ACTION_NAMES.FETCH_IS_SHOW_MENU}`, payload);
};

const isShowMenu = computed(
  () => store.getters[`${APP_MODULE_NAME}/${APP_GETTER_NAMES.IS_SHOW_MENU}`],
);

const onClickedSubMenu = event => {
  event.stopPropagation();
  fetchMenuState(false);
  // 검색된 query params가 있었다면 초기화
  clearRouteQuery();
};

const iconComponent = iconName => {
  const icon = `${capitalize(iconName)}Icon`;
  return heroIcons[icon];
};

const onClickSignout = () => {
  emit('click:signout');
};

onMounted(() => {
  document.addEventListener('mouseup', event => {
    if (event.target.classList.contains('app-menu-overlay')) {
      fetchMenuState(false);
    }
  });
});
</script>
