<template>
  <div>
    <button
      type="button"
      :aria-label="ariaLabel"
      class="inline-flex items-center justify-center w-full border px-6 py-1.5 rounded-md text-sm leading-6 outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary shadow-sm transition ease-in-out duration-150"
      :disabled="disabled"
      :class="[setButtonStyle]"
    >
      <span>{{ buttonText }}</span>
    </button>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  ariaLabel: {
    type: String,
    default: '',
  },
  buttonText: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'primary',
  },
  buttonColor: {
    type: String,
    default: 'bg-primary',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const secondaryColor = 'bg-gray-200';

// eslint-disable-next-line vue/no-setup-props-destructure
const ICON_BUTTON_VARIANTS = {
  primary: `${props.buttonColor} text-white`,
  secondary: secondaryColor,
};

const setButtonStyle = computed(() => {
  switch (props.type) {
    case 'secondary':
      return ICON_BUTTON_VARIANTS.secondary;
    default:
      return ICON_BUTTON_VARIANTS.primary;
  }
});
</script>
