import myArticleApi from './my-article.api.js';
import linkApi from './link.api.js';
import fileApi from './file.api.js';
import transferApi from './transfer.api.js';

export default $axios => ({
  basic: myArticleApi($axios),
  link: linkApi($axios),
  file: fileApi($axios),
  transfer: transferApi($axios),
});
