import { createApp } from 'vue';
import Toast from 'vue-toastification';
import VueSweetalert2 from 'vue-sweetalert2';
import { LoadingPlugin } from 'vue-loading-overlay';
import VueCountdown from '@chenfengyuan/vue-countdown';
import App from './App.vue';
import router from './router/router.js';
import store from './store/store.js';

import 'sweetalert2/dist/sweetalert2.min.css';
import Options from './utils/sweet-alert-option.util.js';

import 'vue-toastification/dist/index.css';
import '@/assets/css/main.css';

import Notify from './utils/notify.util.js';

import 'vue-loading-overlay/dist/css/index.css';

window.Notify = Notify;

createApp(App)
  .use(store)
  .use(router)
  .use(Toast)
  .use(VueSweetalert2, Options)
  .use(LoadingPlugin)
  .component(VueCountdown.name, VueCountdown)
  .mount('#app');
