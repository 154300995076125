<template>
  <div class="wrap">
    <SwitchGroup>
      <SwitchLabel class="block text-sm font-medium text-gray-700">{{ label }}</SwitchLabel>
      <Switch
        v-model="enabled"
        :class="enabled ? 'bg-primary' : 'bg-gray-300'"
        class="relative inline-flex items-center h-6 rounded-full border-transparent w-11 ease-in-out duration-200 outline-none focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
      >
        <span class="sr-only">{{ label }}</span>
        <span
          :class="enabled ? 'translate-x-6' : 'translate-x-1'"
          class="inline-block w-4 h-4 transform bg-white rounded-full shadow-lg ring-0 transition ease-in-out duration-200"
        />
      </Switch>
    </SwitchGroup>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { Switch, SwitchLabel, SwitchGroup } from '@headlessui/vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const enabled = computed({
  get() {
    return props.modelValue;
  },
  set(checked) {
    emit('update:modelValue', checked);
  },
});
</script>
