const PREFIX = '/attachment-file';

const headers = {
  'Content-Type': 'multipart/form-data',
};

export default $axios => {
  return {
    /**
     * 파일 업로드
     * @param {Object} option
     * @param {String} fileName 이름
     * @param {String} explain 설명
     * @param {String} fileDivisionCode 구분코드
     * @param {binary} file
     */
    upload: async params => {
      return $axios.put(`${PREFIX}/upload`, params, { headers }).then(response => response.data);
    },

    /**
     * 파일 다운로드
     * @param {Number} fileId 파일 ID
     */
    download: async params => {
      return $axios
        .get(`${PREFIX}/download`, { params, responseType: 'blob' })
        .then(response => response.data);
    },
  };
};
