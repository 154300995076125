<template>
  <g-button
    v-if="buttonType === 'normal'"
    :icon="icon"
    :disabled="disabled"
    :loading="loading"
    :button-text="buttonText"
    @click="onClicked"
  />
  <g-icon-button
    v-else-if="buttonType === 'icon'"
    :icon="icon"
    :disabled="disabled"
    :button-text="buttonText"
    :is-button-text="isButtonText"
    @click="onClicked"
  />
</template>

<script setup>
import { useSweetAlert } from '@/hooks/use-sweet-alert.js';
import GButton from './GButton.vue';
import GIconButton from './GIconButton.vue';

const props = defineProps({
  icon: {
    type: String,
    default: '',
  },
  iconColor: {
    type: String,
    default: '',
  },
  buttonType: {
    type: String,
    default: 'normal',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  outline: {
    type: Boolean,
  },
  hover: {
    type: Boolean,
  },
  padding: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  html: {
    type: String,
    default: '',
  },
  showConfirmButton: {
    type: Boolean,
    default: true,
  },
  confirmButtonText: {
    type: String,
    default: '확인',
  },
  showDenyButton: {
    type: Boolean,
    default: false,
  },
  denyButtonText: {
    type: String,
    default: '',
  },
  denyButtonColor: {
    type: String,
    default: '',
  },
  confirmButtonColor: {
    type: String,
    default: '#2F7097',
  },
  isButtonText: {
    type: Boolean,
    default: false,
  },
  buttonText: {
    type: String,
    default: '',
  },
  disabledButtons: {
    type: Array,
    default: () => {
      return [];
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['on-confirm', 'on-cancel', 'on-deny']);
const { swalConfirm } = useSweetAlert();

const onClicked = () => {
  swalConfirm({
    title: props.title,
    html: props.html,
    showConfirmButton: props.showConfirmButton,
    showDenyButton: props.showDenyButton,
    confirmButtonText: props.confirmButtonText,
    denyButtonText: props.denyButtonText,
    denyButtonColor: props.denyButtonColor,
    confirmButtonColor: props.confirmButtonColor,
    disabledButtons: props.disabledButtons,
  }).then(res => {
    if (res === 'confirm') {
      emit('on-confirm');
    } else if (res === 'dismiss') {
      emit('on-cancel');
    } else if (res === 'deny') {
      emit('on-deny');
    }
  });
};
</script>
