<template>
  <div class="wrap">
    <vue-countdown v-slot="{ totalHours, minutes, seconds }" :time="refreshTokenRemainingTime">
      <div class="flex items-center">
        <g-icon-button icon="ArrowPath" type="secondary" @click="fetchRefreshTokenRenew" />
        <div class="ml-1 text-sm text-red-500">{{ totalHours }}:{{ minutes }}:{{ seconds }}</div>
      </div>
    </vue-countdown>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { MODULE_NAME as APP_MODULE_NAME } from '@/store/modules/app/store.modules.app.js';
import { ACTION_NAMES as APP_ACTION_NAMES } from '@/store/modules/app/action.js';
import { GETTER_NAMES as APP_GETTER_NAMES } from '@/store/modules/app/getter.js';
import { GIconButton } from '@/components/atoms/button/atoms.button.js';
import { notifyMessage } from '@/type/type.js';

const store = useStore();
const refreshTokenRemainingTime = computed(
  () => store.getters[`${APP_MODULE_NAME}/${APP_GETTER_NAMES.GET_REFRESH_TOKEN_REMAINING_TIME}`],
);

const fetchRefreshTokenRenew = async () => {
  try {
    await store.dispatch(`${APP_MODULE_NAME}/${APP_ACTION_NAMES.FETCH_REFRESH_TOKEN_RENEW}`);
    await store.dispatch(
      `${APP_MODULE_NAME}/${APP_ACTION_NAMES.FETCH_REFRESH_TOKEN_REMAINING_TIME}`,
    );
    window.Notify.success({
      message: notifyMessage.auth.refreshTokenRenew,
    });
  } catch (error) {
    console.error('error', error);
  }
};
</script>
