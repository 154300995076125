<template>
  <div>
    <button
      type="button"
      :aria-label="ariaLabel"
      class="inline-flex items-center justify-center w-full rounded-md leading-6"
      :disabled="disabled"
      :class="[setButtonStyle]"
    >
      <component :is="iconComponent" v-if="icon" :class="[size]" aria-hidden="true" />
      <span v-if="isButtonText" class="pl-1 text-sm">{{ buttonText }}</span>
    </button>
  </div>
</template>

<script setup>
import { computed } from 'vue';
// eslint-disable-next-line import/extensions
import * as heroIcons from '@heroicons/vue/24/outline';
import { capitalize } from '@/utils/str.util.js';
import * as customIcons from '@/assets/icons/macro/macro.icons.js';

const props = defineProps({
  ariaLabel: {
    type: String,
    default: '',
  },
  isButtonText: {
    type: Boolean,
    default: false,
  },
  buttonText: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
  iconColor: {
    type: String,
    default: 'text-black',
  },
  type: {
    type: String,
    default: 'primary',
  },
  size: {
    type: String,
    default: 'h-5 w-5',
  },
  buttonColor: {
    type: String,
    default: 'bg-primary',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  customIcon: {
    type: Boolean,
    default: false,
  },
});

// eslint-disable-next-line vue/no-setup-props-destructure
const ICON_BUTTON_VARIANTS = {
  // 배경색을 가진 버튼
  primary: `${props.buttonColor} border text-white px-2 py-1.5 outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary hover:bg-primary/80 shadow-sm transition ease-in-out duration-150`,
  // 아이콘만 있는 버튼
  secondary: `${props.iconColor}`,
};

const setButtonStyle = computed(() => {
  switch (props.type) {
    case 'secondary':
      return ICON_BUTTON_VARIANTS.secondary;
    default:
      return ICON_BUTTON_VARIANTS.primary;
  }
});

const iconComponent = computed(() => {
  const icon = `${capitalize(props.icon)}Icon`;

  if (!props.customIcon) {
    return heroIcons[icon];
  }
  return customIcons[icon];
});
</script>
