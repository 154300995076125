export const MUTATION_NAMES = Object.freeze({
  FETCH_APP_AUTHORITIES: 'FETCH_APP_AUTHORITIES',
  FETCH_REFRESH_TOKEN_REMAINING_TIME: 'FETCH_REFRESH_TOKEN_REMAINING_TIME',
  FETCH_MY_INFO: 'FETCH_MY_INFO',
  SET_IS_SHOW_MENU: 'SET_IS_SHOW_MENU',
  FETCH_MY_SYSTEM_INFO: 'FETCH_MY_SYSTEM_INFO',
});

export const MUTATION = {
  [MUTATION_NAMES.FETCH_APP_AUTHORITIES]: (state, codes) => {
    state.appAuthorities = codes;
  },
  [MUTATION_NAMES.FETCH_REFRESH_TOKEN_REMAINING_TIME]: (state, remainingTime) => {
    state.refreshTokenRemainingTime = remainingTime;
  },
  [MUTATION_NAMES.FETCH_MY_INFO]: (state, info) => {
    state.myInfo = {
      id: info?.userMngId,
      chDivId: info?.chDivId,
      chDivName: null,
      userId: info?.userId,
      userNm: info?.userNm,
      chMqCd: info?.chMqCd,
      deptId: info?.deptId,
      editorType: info?.editorType, // 기사 형식
      can(permission) {
        return state.appAuthorities.includes(permission);
      },
    };
  },
  [MUTATION_NAMES.FETCH_MY_SYSTEM_INFO]: (state, info) => {
    state.myInfo.system = {
      ...info,
    };
  },
  [MUTATION_NAMES.SET_IS_SHOW_MENU]: (state, isShowMenu) => {
    state.isShowMenu = isShowMenu;
  },
};
