<template>
  <div>
    <div class="rounded-sm flex items-center justify-center">
      <check-icon
        v-if="!isCuesheetMatching && approveCode === articleApproveCode.ArticleApprov.id"
        class="h-5 w-5 text-primary-middle"
      />
      <double-check-icon
        v-else-if="
          !isCuesheetMatching && approveCode === articleApproveCode.ArticleCaptionApprove.id
        "
        size="h-4 w-4"
      />
      <!-- 큐시트에 매칭만된 상태 -->
      <circle-icon
        v-else-if="isCuesheetMatching && approveCode === articleApproveCode.NoneApprove.id"
        class="text-primary-middle"
      />
      <!-- 기사출고 + 큐시트 매칭 OR 기사,자막출고 + 큐시트 매칭 -->
      <approve-and-cuesheet-matching-icon v-else-if="isCuesheetMatching" :type="setMatchingIcon" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
// eslint-disable-next-line import/extensions
import { CheckIcon } from '@heroicons/vue/24/outline';
import { articleApproveCode } from '@/constant/codes/codes.js';
import {
  ApproveAndCuesheetMatchingIcon,
  CircleIcon,
  DoubleCheckIcon,
} from '@/assets/icons/article-approve/article-approve.icons.js';

const props = defineProps({
  approveCode: {
    type: String,
    default: articleApproveCode.NoneApprove.id,
  },
  isCuesheetMatching: {
    type: Boolean,
    default: false,
  },
});

const setMatchingIcon = computed(() => {
  if (props.isCuesheetMatching) {
    if (props.approveCode === articleApproveCode.ArticleApprov.id) {
      return 'articleApprovAndCuesheet';
    }
    if (props.approveCode === articleApproveCode.ArticleCaptionApprove.id) {
      return 'articleCaptionApproveAndCuesheet';
    }
  }

  return '';
});
</script>
