<template>
  <TransitionRoot appear as="template" :show="open">
    <Dialog as="div" :static="true" class="fixed z-50 inset-0" :open="open" :unmount="true">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block w-full bg-white align-bottom rounded-lg text-left shadow-xl transform transition-all sm:align-middle"
          >
            <!-- title -->
            <div
              class="bg-primary border-b border-gray-200 px-4 py-2 sm:px-6 rounded-t-lg flex items-center justify-between select-none"
            >
              <!-- title slot -->
              <DialogTitle
                as="h3"
                class="text-base leading-6 font-medium text-gray-900"
                style="
                  border: none;
                  -ms-user-select: none;
                  -moz-user-select: none;
                  -webkit-user-select: none;
                  -khtml-user-select: none;
                  user-select: none;
                  -webkit-user-drag: none;
                  -khtml-user-drag: none;
                  -moz-user-drag: none;
                  -o-user-drag: none;
                  user-drag: none;
                "
              >
                <slot name="title" />
              </DialogTitle>
              <!-- close button -->
              <div class="flex space-x-4">
                <button
                  v-if="close"
                  type="button"
                  class="rounded-md text-white focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  @click="onClose"
                >
                  <span
                    class="sr-only select-none"
                    style="
                      border: none;
                      -ms-user-select: none;
                      -moz-user-select: none;
                      -webkit-user-select: none;
                      -khtml-user-select: none;
                      user-select: none;
                      -webkit-user-drag: none;
                      -khtml-user-drag: none;
                      -moz-user-drag: none;
                      -o-user-drag: none;
                      user-drag: none;
                    "
                    >닫기</span
                  >
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <!-- content -->
            <div>
              <slot name="content" />
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from 'vue';
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
// eslint-disable-next-line import/extensions
import { XMarkIcon } from '@heroicons/vue/24/outline';

defineProps({
  close: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['click:close']);

const open = ref(true);

const onClose = () => {
  emit('click:close');
};
</script>
