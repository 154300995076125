import { useMarsLApi } from '@/apis/mars-l-api/mars-l-api.js';
import { MUTATION_NAMES } from './mutation.js';

export const ACTION_NAMES = Object.freeze({
  DOWNLOAD_FILE: 'DOWNLOAD_FILE',
  UPLOAD_FILE: 'UPLOAD_FILE',
});

export const ACTIONS = {
  [ACTION_NAMES.DOWNLOAD_FILE]: async (context, payload) => {
    const marsLApi = useMarsLApi();

    try {
      const { fileName, fileId, downloadType } = payload;
      const response = await marsLApi.file.basic.download({ fileId });

      const downloadUrl = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = downloadUrl;

      let name = fileName;
      if (downloadType === 'excel') {
        name += '.xlsx';
      } else if (downloadType === 'abc') {
        name += '.abc';
      }

      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.UPLOAD_FILE]: async ({ commit }, params) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.file.basic.upload(params);
      commit(MUTATION_NAMES.UPLOAD_FILE, response.data);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },
};
