import useMarsLApiClient from '@/hooks/use-mars-l-api-client.js';

import authApi from './auth/auth.js';
import companyApi from './company/company.js';
import myApi from './my/my.js';
import myArticleApi from './my-article/my-article.js';
import userApi from './user/user.js';
import programApi from './program/program.js';
import articleApi from './article/article.js';
import cuesheetApi from './cuesheet/cuesheet.js';
import categoryApi from './category/category.js';
import tagApi from './tag/tag.js';
import linkApi from './link/link.js';
import fileApi from './file/file.js';
import newsSourceApi from './news-source/news-source.api.js';

export const useMarsLApi = () => {
  const marsLApiClient = useMarsLApiClient();

  return {
    auth: authApi(marsLApiClient),
    company: companyApi(marsLApiClient),
    my: myApi(marsLApiClient),
    myArticle: myArticleApi(marsLApiClient),
    user: userApi(marsLApiClient),
    program: programApi(marsLApiClient),
    article: articleApi(marsLApiClient),
    cuesheet: cuesheetApi(marsLApiClient),
    category: categoryApi(marsLApiClient),
    tag: tagApi(marsLApiClient),
    link: linkApi(marsLApiClient),
    file: fileApi(marsLApiClient),
    newsSource: newsSourceApi(marsLApiClient),
  };
};

export default useMarsLApi;
