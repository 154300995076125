import * as jose from 'jose';
import { useMarsLApi } from '@/apis/mars-l-api/mars-l-api.js';
import { GETTER_NAMES as AUTH_GETTER_NAMES } from '@/store/modules/auth/getter.js';
import { MODULE_NAME as AUTH_MODULE_NAME } from '@/store/modules/auth/store.modules.auth.js';
import { MUTATION_NAMES } from './mutation.js';

export const ACTION_NAMES = Object.freeze({
  FETCH_APP_AUTHORITIES: 'FETCH_APP_AUTHORITIES',
  FETCH_ACCESS_TOKEN_RENEW: 'FETCH_ACCESS_TOKEN_RENEW',
  FETCH_REFRESH_TOKEN_RENEW: 'FETCH_REFRESH_TOKEN_RENEW',
  FETCH_REFRESH_TOKEN_REMAINING_TIME: 'FETCH_REFRESH_TOKEN_REMAINING_TIME',
  FETCH_MY_INFO: 'FETCH_MY_INFO',
  FETCH_MENU: 'FETCH_MENU',
  FETCH_MY_SYSTEM_INFO: 'FETCH_MY_SYSTEM_INFO',
  FETCH_IS_SHOW_MENU: 'FETCH_IS_SHOW_MENU',
});

export const ACTIONS = {
  /**
   * 나의 권한 목록 조회
   */
  [ACTION_NAMES.FETCH_APP_AUTHORITIES]: async ({ commit }) => {
    const marsLApi = useMarsLApi();

    try {
      const { data } = await marsLApi.my.authority.findAllCode();
      commit(MUTATION_NAMES.FETCH_APP_AUTHORITIES, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  /**
   * Access token 재발급
   * @returns response
   */
  [ACTION_NAMES.FETCH_ACCESS_TOKEN_RENEW]: async () => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.auth.tokenApi.tokenRenew();
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  /**
   * Refresh token 재발급
   * @returns response
   */
  [ACTION_NAMES.FETCH_REFRESH_TOKEN_RENEW]: async () => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.auth.tokenApi.refreshTokenRenew();
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  /**
   * Refresh 토큰 남은 시간 조회
   */
  [ACTION_NAMES.FETCH_REFRESH_TOKEN_REMAINING_TIME]: async ({ commit }) => {
    const marsLApi = useMarsLApi();

    try {
      const { data } = await marsLApi.auth.tokenApi.getRemainingTimeOfRefreshToken();
      commit(MUTATION_NAMES.FETCH_REFRESH_TOKEN_REMAINING_TIME, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  /**
   * 나의 정보
   */
  [ACTION_NAMES.FETCH_MY_INFO]: async ({ commit, rootGetters }) => {
    const accessToken = rootGetters[`${AUTH_MODULE_NAME}/${AUTH_GETTER_NAMES.GET_ACCESS_TOKEN}`];
    commit(MUTATION_NAMES.FETCH_MY_INFO, !accessToken ? {} : jose.decodeJwt(accessToken));
  },

  /**
   * 나의 시스템 정보
   */
  [ACTION_NAMES.FETCH_MY_SYSTEM_INFO]: async ({ commit }) => {
    const marsLApi = useMarsLApi();

    try {
      const { data } = await marsLApi.my.system.findAll();
      commit(MUTATION_NAMES.FETCH_MY_SYSTEM_INFO, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  /**
   * 메뉴 토글
   */
  [ACTION_NAMES.FETCH_IS_SHOW_MENU]: async ({ commit }, isShowMenu) => {
    commit(MUTATION_NAMES.SET_IS_SHOW_MENU, isShowMenu);
  },
};
