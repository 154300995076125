const PREFIX = '/auth';

export default $axios => ({
  /**
   * 사용자 로그인
   * @param {Object} loginData
   * @param {number} loginData.chDivId - 지역사 코드
   * @param {string} loginData.pwd - 패스워드
   * @param {string} loginData.userId - ID
   * @returns Object - { accessToken }
   */
  signIn: async params => $axios.post(`${PREFIX}/login`, params).then(response => response.data),

  /**
   * 사용자 로그아웃
   */
  signOut: async () => {
    $axios.post(`${PREFIX}/logout`).then(response => response.data);
  },
});
