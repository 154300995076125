const PREFIX = '/news-source';

export default $axios => {
  return {
    /**
     * 취재원 목록 조회
     * @param params {any} - 채널들의 아이디 리스트(channelDivisionIds)
     * */
    findAll: async params => {
      return $axios.get(`${PREFIX}`, { params }).then(response => response.data);
    },
    /**
     * 취재원 상세 조회
     * @param newsSourceId {string|number} - 취재원 ID
     * */
    findOne: async newsSourceId => {
      return $axios.get(`${PREFIX}/${newsSourceId}`).then(response => response.data);
    },
  };
};
