const PREFIX = '/user';

export default $axios => {
  return {
    /**
     * 사용자 조회
     * @param {Object} option
     * @param {number} option.chDivId 채널 구분 아이디
     * @param {string} option.searchValue 검색 키워드
     * @param {string} option.delYn 삭제 여부
     */
    findAll: async params => {
      return $axios.get(`${PREFIX}`, { params }).then(response => response.data);
    },

    /**
     * 사용자 상세조회
     * @param {number} userMngId 사용자 고유 ID 필수값
     */
    findOne: async userMngId => {
      return $axios.get(`${PREFIX}/${userMngId}`).then(response => response.data);
    },

    /**
     * 사용자 생성
     * @param {Object} options 생성에 필요한 값
     * @param {String} options.userId 사용자 ID
     * @param {String} options.userName 사용자 이름
     * @param {String} options.password 비밀번호
     * @param {String} options.email 이메일
     * @param {Number} options.departmentId 부서 ID
     * @param {String} options.useEndDtm 사용만료일
     * @param {Array} options.userGroupIdList 부서ID 리스트
     */
    create: async params => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 사용자 수정
     * @param {number} userMngId 사용자 고유 ID 필수값
     */
    update: async (userMngId, params) => {
      return $axios.put(`${PREFIX}/${userMngId}`, params).then(response => response.data);
    },

    /**
     * 사용자 삭제
     * @param {number} userMngId 사용자 고유 ID 필수값
     */
    delete: async userMngId => {
      return $axios.delete(`${PREFIX}/${userMngId}`).then(response => response.data);
    },

    /**
     * 사용자 비밀번호 변경
     * @param {number} userMngId 사용자 고유 ID 필수값
     * @param {string} changePassword 변경할 비밀번호
     */
    updatePassword: async (userMngId, params) => {
      return $axios
        .put(`${PREFIX}/${userMngId}/change-password`, params)
        .then(response => response.data);
    },
  };
};
