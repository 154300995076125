import { useMarsLApi } from '@/apis/mars-l-api/mars-l-api.js';
import { MUTATION_NAMES } from './mutation.js';

export const ACTION_NAMES = Object.freeze({
  FETCH_CUESHEET: 'FETCH_CUESHEET',
  FETCH_CUESHEET_ITEMS: 'FETCH_CUESHEET_ITEMS',
  FETCH_CUESHEET_INFO: 'FETCH_CUESHEET_INFO',
  FETCH_DETAIL_CUESHEET_ITEM: 'FETCH_DETAIL_CUESHEET_ITEM',
});

export const ACTIONS = {
  [ACTION_NAMES.FETCH_CUESHEET]: async ({ commit }, payload) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.cuesheet.basic.findAll(payload);
      commit(MUTATION_NAMES.FETCH_CUESHEET, response.data);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.FETCH_CUESHEET_ITEMS]: async ({ commit }, payload) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.cuesheet.cuesheetItem.findAll(payload);
      commit(MUTATION_NAMES.FETCH_CUESHEET_ITEMS, response.data);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.FETCH_CUESHEET_INFO]: async ({ commit }, payload) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.cuesheet.basic.findOne(payload);
      commit(MUTATION_NAMES.FETCH_CUESHEET_INFO, response.data);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.FETCH_DETAIL_CUESHEET_ITEM]: async ({ commit }, payload) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.cuesheet.cuesheetItem.findOne(payload);
      commit(MUTATION_NAMES.FETCH_DETAIL_CUESHEET_ITEM, response.data);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.FETCH_DETAIL_CUESHEET_ITEM_PROMPTER]: async ({ commit }, payload) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.cuesheet.cuesheetItem.findAllPrompter(payload);
      commit(MUTATION_NAMES.FETCH_DETAIL_CUESHEET_ITEM_PROMPTER, response.data);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },
};
