// eslint-disable-next-line import/prefer-default-export
export const STATE = {
  articles: [],
  detailArticle: null,
  articleLink: [],
  articleFile: [],
  isTriggerGetData: {
    activate: null,
    status: false,
  },
};
