export const validation = {
  require: '필수입력 입니다.',
  invalid: {
    date: {
      timeFormat: '시간을 다시 확인해주세요.',
    },
    sign: `아이디 또는 비밀번호가 잘못 입력 되었습니다. 아이디와 비밀번호를 정확히 입력해 주세요.`,
  },
};

export default validation;
