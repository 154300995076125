export const color = {
  broadcastType: {
    background: {
      tv: 'bg-yellow-500',
      radio: 'bg-sky-500',
    },
  },
  broadcastStatus: {
    background: {
      noneConfirmed: 'bg-primary',
      confirmed: 'bg-blue-400',
      onAir: 'bg-green-400',
      offAir: 'bg-gray-400',
    },
  },
};

export default color;
