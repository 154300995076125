<template>
  <img :src="require('@/assets/images/logo.svg')" alt="logo" />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return {};
  },
});
</script>
