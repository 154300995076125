<template>
  <footer>
    <div class="mx-3">
      <div class="flex justify-center">
        <div class="flex space-x-1">
          <div class="text-[0.438rem] text-gray-400">
            방송문화진흥회의 지역방송발전지원사업의 지원을 받아 제작하였습니다.
          </div>
          <img
            class="w-20"
            src="@/assets/images/broadcastCultureLogo.png"
            alt="방송문화진흥회 로고"
          />
        </div>
      </div>
      <div class="flex justify-center mt-1">
        <span class="text-xs text-gray-400"> ⓒ2023 Geminisoft Co., Ltd All rights reserved. </span>
      </div>
    </div>
  </footer>
</template>
