const PREFIX = '/tag';

export default $axios => {
  return {
    /**
     * 태그 목록 조회
     * @param {object} params 목록 조회
     * @param {string} params.delYn 삭제 여부
     * @param {string} params.search 태그명 검색 키워드
     */
    findAll: async params => {
      return $axios.get(`${PREFIX}`, { params }).then(response => response.data);
    },
    /**
     * 태그 등록
     * @param {object} params request body
     * @param {string} params.tagNm 태그명
     */
    create: async params => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },
    /**
     * 태그 수정
     * @param {number} tagId 태그ID
     * @param {object} params 태그 수정 데이터
     * @param {string} params.tagNm 태그명
     */
    update: async (tagId, params) => {
      return $axios.put(`${PREFIX}/${tagId}`, params).then(response => response.data);
    },
    /**
     * 태그 삭제
     * @param {number} tagId 태그ID
     */
    delete: async tagId => {
      return $axios.delete(`${PREFIX}/${tagId}`).then(response => response.data);
    },
    /**
     * 태그 복구
     * @param {number} tagId 태그ID
     */
    recovery: async tagId => {
      return $axios.put(`${PREFIX}/${tagId}/recovery`).then(response => response.data);
    },
  };
};
