const cuesheetStateCode = {
  NoneConfirmed: {
    id: 'cue_st_001',
    name: '미확정',
    label: '미확정',
  },

  Confirmed: {
    id: 'cue_st_002',
    name: '확정',
    label: '확정',
  },

  OnAir: {
    id: 'cue_st_003',
    name: '방송중',
    label: '방송중',
  },

  OffAir: {
    id: 'cue_st_004',
    name: '방송완료',
    label: '방송완료',
  },
};

export default cuesheetStateCode;
