export const MUTATION_NAMES = Object.freeze({
  FETCH_CUESHEET: 'FETCH_CUESHEET',
  FETCH_CUESHEET_ITEMS: 'FETCH_CUESHEET_ITEMS',
  FETCH_CUESHEET_INFO: 'FETCH_CUESHEET_INFO',
  FETCH_DETAIL_CUESHEET_ITEM: 'FETCH_DETAIL_CUESHEET_ITEM',
  FETCH_DETAIL_CUESHEET_ITEM_PROMPTER: 'FETCH_DETAIL_CUESHEET_ITEM_PROMPTER',
});

export const MUTATION = {
  [MUTATION_NAMES.FETCH_CUESHEET]: (state, cuesheet) => {
    state.cuesheet = cuesheet;
  },
  [MUTATION_NAMES.FETCH_CUESHEET_ITEMS]: (state, cuesheetItems) => {
    state.cuesheetItems = cuesheetItems;
  },
  [MUTATION_NAMES.FETCH_CUESHEET_INFO]: (state, cuesheetInfo) => {
    state.cuesheetInfo = cuesheetInfo;
  },
  [MUTATION_NAMES.FETCH_DETAIL_CUESHEET_ITEM]: (state, detailCuesheetItem) => {
    state.detailCuesheetItem = detailCuesheetItem;
  },
  [MUTATION_NAMES.FETCH_DETAIL_CUESHEET_ITEM_PROMPTER]: (state, prompterItems) => {
    state.prompterItems = prompterItems;
  },
};
