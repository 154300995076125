export const GETTER_NAMES = Object.freeze({
  // 내 지역사 포함
  GET_ALL_COMPANY: 'GET_ALL_COMPANY',
  // 내 지역사 제외
  GET_OTHER_COMPANY: 'GET_OTHER_COMPANY',
});

export const GETTER = {
  [GETTER_NAMES.GET_ALL_COMPANY]: state => {
    if (!state.company) {
      return [];
    }

    return state.company;
  },
};
