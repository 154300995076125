import { useMarsLApi } from '@/apis/mars-l-api/mars-l-api.js';
import { MUTATION_NAMES } from './mutation.js';

export const ACTION_NAMES = Object.freeze({
  FETCH_ARTICLES: 'FETCH_ARTICLES',
  FETCH_DETAIL_ARTICLE: 'FETCH_DETAIL_ARTICLE',
  FETCH_ARTICLE_LINK: 'FETCH_ARTICLE_LINK',
  FETCH_ARTICLE_FILE: 'FETCH_ARTICLE_FILE',
  IS_TRIGGER_GET_DATA: 'IS_TRIGGER_GET_DATA',
});

export const ACTIONS = {
  [ACTION_NAMES.FETCH_ARTICLES]: async ({ commit }, payload) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.article.basic.findAll(payload);
      commit(MUTATION_NAMES.FETCH_ARTICLES, response.data);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.FETCH_DETAIL_ARTICLE]: async ({ commit }, payload) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.article.basic.findOne(payload);
      commit(MUTATION_NAMES.FETCH_DETAIL_ARTICLE, response.data);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.FETCH_ARTICLE_LINK]: async ({ commit }, payload) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.article.link.findAll(payload);
      commit(MUTATION_NAMES.FETCH_ARTICLE_LINK, response.data);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.FETCH_ARTICLE_FILE]: async ({ commit }, params) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.article.file.findAll(params);
      commit(MUTATION_NAMES.FETCH_ARTICLE_FILE, response.data);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  [ACTION_NAMES.IS_TRIGGER_GET_DATA]: ({ commit }, payload) => {
    commit(MUTATION_NAMES.IS_TRIGGER_GET_DATA, payload);
  },
};
