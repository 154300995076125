export const date = {
  // VueDatePicker에서 사용하는 타입
  formatYmd: 'yy-MM-dd',
  formatYmdHm: 'yy-MM-dd HH:mm',
  formatYmdHms: 'yy-MM-dd HH:mm:ss',
  modelTypeYmd: 'yyyy-MM-dd',
  modelTypeYmdHm: 'yyyy-MM-dd HH:mm',
  modelTypeYmdHms: 'yyyy-MM-dd HH:mm:ss',
  // dayjs에서 사용하는 타입
  stringYmd: 'YYYY-MM-DD',
  stringYmdHm: 'YYYY-MM-DD HH:mm',
  stringYmdHms: 'YYYY-MM-DD HH:mm:ss',
};

export default date;
