export const DefaultType = {
  program: {
    id: null,
    brdcPgmNm: '프로그램 전체',
  },
  user: {
    id: null,
    userId: null,
    userNm: '기자 전체',
  },
  approve: {
    id: null,
    name: '출고 여부 전체',
    label: '출고 여부 전체',
  },
  articleType: {
    id: null,
    label: '형식 전체',
  },
  category: {
    cateId: null,
    cateNm: '카테고리 전체',
  },
  articleDivision: {
    id: null,
    name: '구분 전체',
    label: '구분 전체',
  },
};

export default DefaultType;
