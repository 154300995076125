export const AppAuthorityEnum = {
  /**
   * name: '작성',
     explain: '내기사 생성, 수정, 삭제',
   */
  MY_ARTICLE_WRITE: 'my_article_write',
  /**
   * name: '송고',
   */
  MY_ARTICLE_SEND: 'my_article_send',
  /**
   * name: '전체 조회',
   */
  ARTICLE_READ: 'article_read',
  /**
   * name: '작성',
     explain: '작성, 수정',
   */
  ARTICLE_WRITE: 'article_write',
  /**
   * name: '복사',
   */
  ARTICLE_COPY: 'article_copy',
  /**
   * name: '출고',
     explain: '출고, 취소',
   */
  ARTICLE_RELEASE: 'article_release',
  /**
   * name: '포탈 전송',
   */
  ARTICLE_HOMEPAGE_SEND: 'article_homepage_send',
  /**
   * name: '인쇄',
   */
  ARTICLE_PRINT: 'article_print',
  /**
   * name: '지역사 조회',
   */
  ARTICLE_CHANNEL_READ: 'article_channel_read',
  /**
   * name: '서울 MBC 조회',
   */
  ARTICLE_SEOUL_READ: 'article_seoul_read',
  /**
   * name: '전체 조회',
   */
  CUESHEET_READ: 'cuesheet_read',
  /**
   * name: '작성',
     explain: '생성, 수정, 삭제',
   */
  CUESHEET_WRITE: 'cuesheet_write',
  /**
   * name: '지역사 조회',
   */
  CUESHEET_CHANNEL_READ: 'cuesheet_channel_read',
  /**
   * name: '서울 MBC 조회',
   */
  CUESHEET_SEOUL_READ: 'cuesheet_seoul_read',
  /**
   * name: '기사 아이템 작성',
     explain: '아이템 생성, 순서 변경',
   */
  CUESHEET_DETAIL_ITEM_WRITE: 'cuesheet_detail_item_write',
  /**
   * name: '인쇄',
   */
  CUESHEET_DETAIL_PRINT: 'cuesheet_detail_print',
  /**
   * name: '앵커 멘트 수정',
     explain: '앵커 기사 수정, 확정',
   */
  CUESHEET_DETAIL_ANCHOR_WRITE: 'cuesheet_detail_anchor_write',
  /**
   * name: '큐시트 템플릿 조회',
   */
  CUESHEET_TEMPLATE_READ: 'cuesheet_template_read',
  /**
   * name: '큐시트 템플릿 작성',
     explain: '큐시트 템플릿 생성, 수정, 삭제',
   */
  CUESHEET_TEMPLATE_WRITE: 'cuesheet_template_write',
  /**
   * name: '조회',
   */
  REPORT_READ: 'report_read',
  /**
   * name: '작성',
     explain: '생성, 수정',
   */
  REPORT_WRITE: 'report_write',
  /**
   * name: '조회',
   */
  MONITORING_READ: 'monitoring_read',
  /**
   * name: '작성',
     explain: '생성, 수정, 삭제',
   */
  MONITORING_WRITE: 'monitoring_write',
  /**
   * name: '조회',
   */
  BOARD_READ: 'board_read',
  /**
   * name: '작성',
     explain: '생성, 수정, 삭제',
   */
  BOARD_WRITE: 'board_write',
  /**
   * name: '댓글 작성',
     explain: '생성, 수정, 삭제',
   */
  BOARD_REPLY_WRITE: 'board_reply_write',
  /**
   * name: '공지 작성',
     explain: '생성, 수정, 삭제',
   */
  BOARD_NOTICE_WRITE: 'board_notice_write',
  /**
   * name: '조회',
   */
  SCROLLNEWS_READ: 'scrollnews_read',
  /**
   * name: '생성, 수정, 삭제',
   */
  SCROLLNEWS_WRITE: 'scrollnews_write',
  /**
   *
   */
  SCROLLNEWS_SEND: 'scrollnews_send',
  /**
   * name: '관리자 페이지 접근',
     explain: '화면 접근만 제어',
   */
  ADMIN_VIEW: 'admin_view',
};

export default { AppAuthorityEnum };
