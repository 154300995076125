import { useMarsLApi } from '@/apis/mars-l-api/mars-l-api.js';
import { MODULE_NAME as APP_MODULE_NAME } from '@/store/modules/app/store.modules.app.js';
import { GETTER_NAMES as APP_GETTER_NAMES } from '@/store/modules/app/getter.js';
import { MUTATION_NAMES } from './mutation.js';

export const ACTION_NAMES = Object.freeze({
  FETCH_USERS: 'FETCH_USERS',
});

export const ACTIONS = {
  [ACTION_NAMES.FETCH_USERS]: async ({ commit, rootGetters }) => {
    const marsLApi = useMarsLApi();

    const { chDivId } = rootGetters[`${APP_MODULE_NAME}/${APP_GETTER_NAMES.GET_MY_INFO}`];

    const params = {
      chDivId,
      delYn: 'N',
      size: 10000,
    };

    try {
      const response = await marsLApi.user.basic.findAll(params);
      commit(MUTATION_NAMES.FETCH_USERS, response.data.content);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },
};
