export const MUTATION_NAMES = Object.freeze({
  FETCH_MY_ARTICLES: 'FETCH_MY_ARTICLES',
  FETCH_DETAIL_MY_ARTICLE: 'FETCH_DETAIL_MY_ARTICLE',
  FETCH_MY_ARTICLE_LINK: 'FETCH_MY_ARTICLE_LINK',
  FETCH_MY_ARTICLE_FILE: 'FETCH_MY_ARTICLE_FILE',
});

export const MUTATION = {
  [MUTATION_NAMES.FETCH_MY_ARTICLES]: (state, articles) => {
    state.myArticles = articles;
  },
  [MUTATION_NAMES.FETCH_DETAIL_MY_ARTICLE]: (state, article) => {
    state.detailMyArticle = article;
  },
  [MUTATION_NAMES.FETCH_MY_ARTICLE_LINK]: (state, link) => {
    state.myArticleLink = link;
  },
  [MUTATION_NAMES.FETCH_MY_ARTICLE_FILE]: (state, file) => {
    state.myArticleFile = file;
  },
};
