export const GETTER_NAMES = Object.freeze({
  IS_AUTHENTICATED: 'IS_AUTHENTICATED',
  GET_ACCESS_TOKEN: 'GET_ACCESS_TOKEN',
});

export const GETTER = {
  [GETTER_NAMES.IS_AUTHENTICATED]: state => {
    return !!(localStorage.getItem('accessToken') || state.accessToken);
  },
  [GETTER_NAMES.GET_ACCESS_TOKEN]: state =>
    state.accessToken || localStorage.getItem('accessToken'),
};
