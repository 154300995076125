export const GETTER_NAMES = Object.freeze({
  GET_ARTICLES: 'GET_ARTICLES',
  GET_DETAIL_ARTICLE: 'GET_DETAIL_ARTICLE',
  GET_ARTICLE_LINK: 'GET_ARTICLE_LINK',
  GET_ARTICLE_FILE: 'GET_ARTICLE_FILE',
  IS_TRIGGER_GET_DATA: 'IS_TRIGGER_GET_DATA',
});

export const GETTER = {
  [GETTER_NAMES.GET_ARTICLES]: state => state.articles,
  [GETTER_NAMES.GET_DETAIL_ARTICLE]: state => state.detailArticle,
  [GETTER_NAMES.GET_ARTICLE_LINK]: state => state.articleLink,
  [GETTER_NAMES.GET_ARTICLE_FILE]: state => state.articleFile,
  [GETTER_NAMES.IS_TRIGGER_GET_DATA]: state => state.isTriggerGetData,
};
