const PREFIX = '/my-info';

export default $axios => ({
  /**
   * 나의 시스템 설정 정보 조회
   */
  findAll: async () => $axios.get(`${PREFIX}/system`).then(response => response.data),

  /**
   * 나의 시스템 설정 정보 수정
   * @param {Object} option request body
   * @param {string} option.userSpeedSettingEnum 발음속도
   * @param {string} option.isAutoSave 자동 저장 여부
   */
  update: async params => $axios.put(`${PREFIX}/system`, params).then(response => response.data),
});
