<template>
  <div class="relative w-screen h-screen">
    <div class="w-full h-full flex flex-col">
      <div class="w-full h-full">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script setup></script>
