export const GETTER_NAMES = Object.freeze({
  GET_CUESHEET: 'GET_CUESHEET',
  GET_CUESHEET_ITEMS: 'GET_CUESHEET_ITEMS',
  GET_CUESHEET_INFO: 'GET_CUESHEET_INFO',
  GET_DETAIL_CUESHEET_ITEM: 'GET_DETAIL_CUESHEET_ITEM',
});

export const GETTER = {
  [GETTER_NAMES.GET_CUESHEET]: state => state.cuesheet,
  [GETTER_NAMES.GET_CUESHEET_ITEMS]: state => state.cuesheetItems,
  [GETTER_NAMES.GET_CUESHEET_INFO]: state => state.cuesheetInfo,
  [GETTER_NAMES.GET_DETAIL_CUESHEET_ITEM]: state => state.detailCuesheetItem,
};
