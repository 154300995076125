class CuesheetItemCode {
  Item = {
    id: 'cue_000',
    name: '아이템',
    label: '아이템',
  };

  Template = {
    id: 'cue_001',
    name: '템플릿',
    label: '템플릿',
  };

  Article = {
    id: 'cue_002',
    name: '기사',
    label: '기사',
  };

  values = [this.Item, this.Template, this.Article];
}

export default new CuesheetItemCode();
