import { useMarsLApi } from '@/apis/mars-l-api/mars-l-api.js';
import { MUTATION_NAMES } from './mutation.js';

export const ACTION_NAMES = Object.freeze({
  FETCH_COMPANY: 'FETCH_COMPANY',
  FETCH_DETAIL_COMPANY: 'FETCH_DETAIL_COMPANY',
});

export const ACTIONS = {
  [ACTION_NAMES.FETCH_COMPANY]: async ({ commit }) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.company.basic.findAll();
      commit(MUTATION_NAMES.SET_COMPANY, response.data);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.FETCH_DETAIL_COMPANY]: async (context, params) => {
    const marsLApi = useMarsLApi();

    try {
      return await marsLApi.company.basic.findOne(params);
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },
};
