import { useMarsLApi } from '@/apis/mars-l-api/mars-l-api.js';
import { MUTATION_NAMES } from './mutation.js';

export const ACTION_NAMES = Object.freeze({
  FETCH_PROGRAM: 'FETCH_PROGRAM',
});

export const ACTIONS = {
  [ACTION_NAMES.FETCH_PROGRAM]: async ({ commit }) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.program.basic.findAll();
      commit(MUTATION_NAMES.FETCH_PROGRAM, response.data);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },
};
