const articleTypeCode = {
  Straight: {
    id: 'typ_001',
    seoulId: '01',
    name: '스트레이트',
    label: '스트레이트',
  },

  Report: {
    id: 'typ_002',
    seoulId: '02',
    name: '리포트',
    label: '리포트',
  },

  CrossTalk: {
    id: 'typ_003',
    seoulId: '03',
    name: 'C/T',
    label: 'C/T',
  },

  BottomRole: {
    id: 'typ_004',
    seoulId: '04',
    name: '하단롤',
    label: '하단롤',
  },
};

export default articleTypeCode;
