const PREFIX = '/my-article';

export default $axios => {
  return {
    /**
     * 내기사 파일 조회
     * @params {number} articleId 기사 ID
     */
    findAll: async articleId => {
      return $axios.get(`${PREFIX}/${articleId}/attachment-file`).then(response => response.data);
    },

    /**
     * 내기사 파일 등록
     * @param {number} articleId 기사 ID
     * @param {Object} option request body
     * @param {string} option.fileId 파일 ID
     */
    create: async (articleId, params) => {
      return $axios
        .post(`${PREFIX}/${articleId}/attachment-file`, params)
        .then(response => response.data);
    },

    /**
     * 내기사 파일 삭제
     * @param {number} articleId 기사 ID
     * @param {number} id 파일 ID
     */
    delete: async (articleId, id) => {
      return $axios
        .delete(`${PREFIX}/${articleId}/attachment-file/${id}`)
        .then(response => response.data);
    },
  };
};
