import { useMarsLApi } from '@/apis/mars-l-api/mars-l-api.js';
import { MUTATION_NAMES } from './mutation.js';

export const ACTION_NAMES = Object.freeze({
  FETCH_TAG: 'FETCH_TAG',
  CREATE_TAG: 'CREATE_TAG',
});

export const ACTIONS = {
  [ACTION_NAMES.FETCH_TAG]: async ({ commit }) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.tag.basic.findAll({ delYn: 'N' });
      commit(MUTATION_NAMES.FETCH_TAG, response.data.tagDetailList);
      return response;
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },

  [ACTION_NAMES.CREATE_TAG]: async (context, params) => {
    const marsLApi = useMarsLApi();

    try {
      return await marsLApi.tag.basic.create(params);
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  },
};
