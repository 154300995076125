import { useMarsLApi } from '@/apis/mars-l-api/mars-l-api.js';
import { MUTATION_NAMES } from './mutation.js';

export const ACTION_NAMES = Object.freeze({
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
  CLEAR_TOKEN: 'CLEAR_TOKEN',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
});

export const ACTIONS = {
  [ACTION_NAMES.SET_ACCESS_TOKEN]: async ({ commit }, accessToken) => {
    localStorage.setItem('accessToken', accessToken);
    commit(MUTATION_NAMES.SET_ACCESS_TOKEN, accessToken);
  },

  [ACTION_NAMES.CLEAR_TOKEN]: async ({ commit }) => {
    localStorage.removeItem('accessToken');
    commit(MUTATION_NAMES.CLEAR_TOKEN);
  },

  [ACTION_NAMES.SIGN_IN]: async ({ dispatch }, params) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.auth.signApi.signIn(params);
      dispatch(`${ACTION_NAMES.SET_ACCESS_TOKEN}`, response.data.accessToken);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  [ACTION_NAMES.SIGN_OUT]: async ({ dispatch }) => {
    const marsLApi = useMarsLApi();

    try {
      const response = await marsLApi.auth.signApi.signOut();
      dispatch(`${ACTION_NAMES.CLEAR_TOKEN}`);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
