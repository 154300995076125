const articleDivisionCode = {
  Basic: {
    id: 'div_001',
    seoulId: '01',
    name: '일반',
    label: '일반',
  },

  Expected: {
    id: 'div_002',
    seoulId: '02',
    name: '예정',
    label: '예정',
  },

  Embargo: {
    id: 'div_003',
    seoulId: '03',
    name: '엠바고',
    label: '엠바고',
  },
};

export default articleDivisionCode;
