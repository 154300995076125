const articleApproveCode = {
  ApproveAll: {
    id: 'apprv_typ_001,apprv_typ_002',
    name: '출고',
    label: '출고',
    order: 1,
  },

  NoneApprove: {
    id: 'apprv_typ_000',
    name: '미출고',
    label: '미출고',
    order: 2,
  },

  ArticleApprov: {
    id: 'apprv_typ_001',
    name: '기사',
    label: '기사',
    order: 3,
  },

  ArticleCaptionApprove: {
    id: 'apprv_typ_002',
    name: '기사+자막',
    label: '기사+자막',
    order: 4,
  },
};

export default articleApproveCode;
