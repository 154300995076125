const PREFIX = '/auth';

export default $axios => ({
  /**
   * Access 토큰 갱신 (재발급)
   */
  tokenRenew: async () => $axios.post(`${PREFIX}/token-renew`).then(response => response.data),

  /**
   * Refresh 토큰 갱신 (재발급)
   */
  refreshTokenRenew: async () =>
    $axios.post(`${PREFIX}/renew/refresh-token`).then(response => response.data),

  /**
   * Refresh 토큰 남은 시간 조회
   */
  getRemainingTimeOfRefreshToken: async () =>
    $axios.get(`${PREFIX}/refresh-token/remaining-time`).then(response => response.data),
});
