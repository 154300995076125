const broadcastTypeCode = {
  Tv: {
    id: '01',
    name: 'TV',
    label: 'TV',
    isUse: true,
  },

  PressProduction: {
    id: '02',
    name: '보도제작',
    label: '보도제작',
    isUse: false,
  },

  Radio: {
    id: '03',
    name: '라디오',
    label: '라디오',
    isUse: true,
  },
};

export default broadcastTypeCode;
