import axios from 'axios';
import store from '@/store/store.js';
import { MODULE_NAME as AUTH_MODULE_NAME } from '@/store/modules/auth/store.modules.auth.js';
import { GETTER_NAMES as AUTH_GETTER_NAMES } from '@/store/modules/auth/getter.js';
import { ACTION_NAMES as AUTH_ACTION_NAMES } from '@/store/modules/auth/action.js';
import { MODULE_NAME as APP_MODULE_NAME } from '@/store/modules/app/store.modules.app.js';
import { ACTION_NAMES as APP_ACTION_NAMES } from '@/store/modules/app/action.js';

let lock = false;
let subscribers = [];

const subscribeTokenRefresh = callback => {
  subscribers.push(callback);
};

const onRefreshed = token => {
  subscribers.forEach(callback => callback(token));
};

const getRefreshToken = async () => {
  try {
    const refreshRes = await store.dispatch(
      `${APP_MODULE_NAME}/${APP_ACTION_NAMES.FETCH_ACCESS_TOKEN_RENEW}`,
    );

    // refresh 토큰 시간이 남아있는 경우, access 토큰 재발급
    if (refreshRes.success) {
      const { accessToken } = refreshRes.data;
      lock = false;
      onRefreshed(accessToken);
      subscribers = [];

      store.dispatch(`${AUTH_MODULE_NAME}/${AUTH_ACTION_NAMES.SET_ACCESS_TOKEN}`, accessToken);
      store.dispatch(`${APP_MODULE_NAME}/${APP_ACTION_NAMES.FETCH_REFRESH_TOKEN_REMAINING_TIME}`);

      throw accessToken;
    }
  } catch (error) {
    lock = false;
    subscribers = [];
  }
};

export default () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_ZODIAC_API_BASE_URL,
    headers: {
      'content-type': 'application/json',
    },
  });

  instance.interceptors.request.use(
    config => {
      const configData = config;
      const accessToken =
        store.getters[`${AUTH_MODULE_NAME}/${AUTH_GETTER_NAMES.GET_ACCESS_TOKEN}`];

      if (accessToken) {
        configData.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    error => Promise.reject(error),
  );

  instance.interceptors.response.use(
    response => response,

    async error => {
      console.error('Response error', error);
      const {
        config,
        response: { data, status },
      } = error;
      const originalRequest = config;

      /**
       * refresh 중복 요청 해결
       * race condition and subscription 방식 사용
       * https://gusrb3164.github.io/web/2022/08/07/refresh-with-axios-for-client/
       */
      // access 토큰 만료
      if (status === 401) {
        // DB에 저장된 refresh 토큰이 만료되었을 경우 로그인 화면으로 이동
        if (config?.url === '/auth/token-renew') {
          // 존재하는 토큰 삭제
          store.dispatch(`${AUTH_MODULE_NAME}/${AUTH_ACTION_NAMES.CLEAR_TOKEN}`);
          // 로그인 페이지로 이동
          window.location.href = '/signin';
        } else {
          let accessToken =
            store.getters[`${AUTH_MODULE_NAME}/${AUTH_GETTER_NAMES.GET_ACCESS_TOKEN}`];

          if (!accessToken) {
            // 로그인 페이지로 이동
            window.location.href = '/signin';
          } else {
            if (lock) {
              return new Promise(resolve => {
                subscribeTokenRefresh(token => {
                  originalRequest.headers.Authorization = `Bearer ${token}`;
                  resolve(instance(originalRequest));
                });
              });
            }
            lock = true;

            try {
              accessToken = await getRefreshToken();
            } catch (getRefreshTokenError) {
              console.error('getRefreshTokenError', getRefreshTokenError);
            }
            config.headers.Authorization = `Bearer ${accessToken}`;
            return instance(config);
          }
        }
      } else if (status === 403) {
        window.Notify.error({
          message: '접근 권한이 없습니다. 관리자에게 문의해주세요.',
          duration: 3000,
        });
      } else if (data.errorCode === 10000) {
        window.Notify.error({ message: '입력하신 정보를 확인해주세요.' });
      } else if (status === 500) {
        window.Notify.error({
          message: '장애가 발생했습니다. 관리자에게 문의해주세요.',
        });
      }

      return Promise.reject(error);
    },
  );

  return instance;
};
