export const label = {
  broadcastStatus: {
    noneConfirmed: '미확정',
    confirmed: '확정',
    onAir: '방송중',
    offAir: '방송완료',
  },
  articleDivisionTime: {
    basic: '예정시간',
    expected: '예정시간',
    embargo: '엠바고시간',
  },
};

export default label;
