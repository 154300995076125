import { AppAuthorityEnum } from '@/constant/app-authority.constant.js';

// eslint-disable-next-line import/prefer-default-export
export const STATE = {
  appAuthorities: [],
  refreshTokenRemainingTime: 0,
  isShowMenu: false,
  myInfo: {
    id: null,
    chDivId: null,
    chDivName: null,
    userId: null,
    userNm: null,
    chMqCd: null,
    deptId: null,
    editorType: null, // 기사 형식
    isAdmin: false,
    can: () => false,
  },
  appMenu: [
    {
      path: 'my',
      name: '내기사',
      type: 'group',
      children: [
        {
          path: '/my-article',
          name: '내기사',
          authorities: [AppAuthorityEnum.MY_ARTICLE_WRITE],
          icon: 'bookOpen',
        },
      ],
    },
    {
      path: 'article',
      name: '데스크',
      type: 'group',
      children: [
        {
          path: '/my-region',
          name: '지역MBC데스크',
          authorities: [AppAuthorityEnum.ARTICLE_READ],
          icon: 'clipboardDocumentList',
        },
        // {
        //   path: 'area',
        //   name: '전국MBC데스크',
        //   authorities: [AppAuthorityEnum.ARTICLE_CHANNEL_READ],
        //   icon: 'documentDuplicate',
        // },
      ],
    },
    {
      path: 'cuesheet',
      name: '큐시트',
      type: 'group',
      children: [
        {
          path: '/my-cuesheet',
          name: '지역MBC큐시트',
          authorities: [AppAuthorityEnum.CUESHEET_READ],
          icon: 'inbox',
        },
        // {
        //   path: 'area-desk',
        //   name: '전국MBC큐시트',
        //   authorities: [AppAuthorityEnum.CUESHEET_CHANNEL_READ],
        //   icon: 'inboxStack',
        // },
      ],
    },
    {
      path: 'share-info',
      name: '정보공유',
      type: 'group',
      children: [
        {
          path: '/news-source',
          name: '취재원',
          authorities: [AppAuthorityEnum.ARTICLE_READ],
          icon: 'inbox',
        },
      ],
    },
    // {
    //   path: 'aaa',
    //   name: '상위 메뉴 테스트',
    //   type: 'single',
    //   authorities: [AppAuthorityEnum.MY_ARTICLE_WRITE, AppAuthorityEnum.CUESHEET_READ],
    // },
  ],
};
