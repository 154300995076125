<template>
  <div>
    <component :is="iconComponent" v-if="iconComponent" :size="size" />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { articleTypeCode } from '@/code/code.js';
import * as articleTypeIcon from '@/assets/icons/article-type/article-type.icons.js';

const props = defineProps({
  typeCode: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'w-7 h-7',
  },
});

const iconComponent = computed(() => {
  switch (props.typeCode) {
    case articleTypeCode.Straight.id:
      return articleTypeIcon.StraightTypeIcon;
    case articleTypeCode.Report.id:
      return articleTypeIcon.ReportTypeIcon;
    case articleTypeCode.CrossTalk.id:
      return articleTypeIcon.CrossTalkTypeIcon;
    case articleTypeCode.BottomRole.id:
      return articleTypeIcon.BottomRoleTypeIcon;
    default:
      return '';
  }
});
</script>
