export const notifyMessageType = {
  auth: {
    refreshTokenRenew: '로그인 시간이 갱신되었습니다.',
  },
  article: {
    create: '[기사 저장] 완료되었습니다.',
    edit: '[기사 수정] 완료되었습니다.',
    remove: '[기사 삭제] 완료되었습니다.',
    transfer: '[기사 송고] 완료되었습니다.',
    failTransfer: '[기사 송고] 실패하였습니다.',
    confirmTransfer: '(을)를 송고하시겠습니까?',
    confirmReTransfer: '(을)를 재송고하시겠습니까?',
  },
};

export default notifyMessageType;
