const PREFIX = '/cuesheet';

export default $axios => {
  return {
    /**
     * 큐시트 생성
     * @param params - 생성 내용
     */
    create: async params => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 큐시트 수정
     * @param cuesheetId - 큐시트 ID
     * @param params - 수정 내용
     */
    update: async (cuesheetId, params) => {
      return $axios.put(`${PREFIX}/${cuesheetId}`, params).then(response => response.data);
    },

    /**
     * 큐시트 삭제
     * @param cuesheetId - 큐시트 ID
     */
    delete: async (cuesheetId, params) => {
      return $axios
        .delete(`${PREFIX}/${cuesheetId}`, { data: params })
        .then(response => response.data);
    },

    /**
     * 큐시트 목록 조회
     * @param params
     * @returns
     */
    findAll: async params => {
      return $axios.get(`${PREFIX}`, { params }).then(response => response.data);
    },

    /**
     * 큐시트 정보 조회
     * @param cuesheetId - 큐시트 ID
     */
    findOne: async cuesheetId => {
      return $axios.get(`${PREFIX}/${cuesheetId}`).then(response => response.data);
    },

    /**
     * 큐시트 복사
     * @param cuesheetId - 큐시트 ID
     * @param params - 복사 내용
     */
    copy: async (cuesheetId, params) => {
      return $axios
        .post(`${PREFIX}/${cuesheetId}/cuesheetcopy`, params)
        .then(response => response.data);
    },

    /**
     * 큐시트 오더락
     * @param cuesheetId - 큐시트 ID
     * @param params
     */
    orderLock: async (cuesheetId, params) => {
      return $axios
        .put(`${PREFIX}/${cuesheetId}/order-lock`, params)
        .then(response => response.data);
    },

    /**
     * 큐시트 락해제
     * @param cuesheetId - 큐시트 ID
     * @param params
     */
    unLock: async cuesheetId => {
      return $axios.put(`${PREFIX}/${cuesheetId}/unLock`).then(response => response.data);
    },

    /**
     * 큐시트 아이템 순서 편집
     * @param cuesheetId 큐시트 아이디
     * @param cuesheetItemId 큐시트 아이템 아이디
     * @param params
     * @returns
     */
    updateOrder: async (cuesheetId, cuesheetItemId, params) => {
      return $axios
        .put(`${PREFIX}/${cuesheetId}/item/${cuesheetItemId}/ord`, params)
        .then(response => response.data);
    },

    /**
     * 큐시트 확정
     * @param cuesheetId 큐시트 ID
     * @returns
     */
    confirm: async cuesheetId => {
      return $axios.put(`${PREFIX}/${cuesheetId}/confirm`).then(response => response.data);
    },

    /**
     * 큐시트 확정 취소
     * @param cuesheetId 큐시트 ID
     * @returns
     */
    canceledConfirm: async cuesheetId => {
      return $axios.put(`${PREFIX}/${cuesheetId}/cancel`).then(response => response.data);
    },

    /**
     * 동기화
     * @param cuesheetId  큐시트 아이디
     * @returns
     */
    synchronize: async cuesheetId => {
      return $axios.put(`${PREFIX}/${cuesheetId}/synchronization`).then(response => response.data);
    },

    /**
     * 큐시트 장애인 자막 ftp 전송
     * @param cuesheetId  큐시트 아이디
     * @returns
     */
    transferFTP: async cuesheetId => {
      return $axios
        .put(`${PREFIX}/${cuesheetId}/transfer-ftp-cation`)
        .then(response => response.data);
    },
  };
};
