export const GETTER_NAMES = Object.freeze({
  GET_MY_ARTICLES: 'GET_MY_ARTICLES',
  GET_DETAIL_MY_ARTICLE: 'GET_DETAIL_MY_ARTICLE',
  GET_MY_ARTICLE_LINK: 'GET_MY_ARTICLE_LINK',
  GET_MY_ARTICLE_FILE: 'GET_MY_ARTICLE_FILE',
});

export const GETTER = {
  [GETTER_NAMES.GET_MY_ARTICLES]: state => state.myArticles,
  [GETTER_NAMES.GET_DETAIL_MY_ARTICLE]: state => {
    return state.detailMyArticle;
  },
  [GETTER_NAMES.GET_MY_ARTICLE_LINK]: state => {
    return state.myArticleLink;
  },
  [GETTER_NAMES.GET_MY_ARTICLE_FILE]: state => {
    return state.myArticleFile;
  },
};
